import { create } from "../util/request";
import i18n from 'i18next';
import qs from 'qs';


const api = create({ baseURL: ''})

const langParams = {
  'zh-CN': 'cn',
  'ru-RU': 'ru',
  'en-US': 'en',
  'es-ES': 'es',
  'en': 'en',
  'es': 'es',
  'ru': 'ru',
  'zh': 'cn',
  'cn': 'cn',
}

class ProductCollectionApi {

  async getProductCollectionEntries(id, params) {
    return api.get(`/api/v1/product_collections/${id}/entries?${qs.stringify(params)}`);
  }
}

const productCollectionApi = new ProductCollectionApi();
export default productCollectionApi;