import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Upload, Tooltip, message } from 'antd';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Link } from 'react-router-dom';
import productApi from '../servapi/product';
import Slider from 'react-slick';
import { FireOutlined, CrownOutlined, StarOutlined, LikeOutlined, DollarOutlined, TagOutlined } from '@ant-design/icons';
import './MobileIndex.less';
import { trans } from '../util/transobj';

function formatNumber(num) {
  if (num >= 10000) {
    return (num / 10000).toFixed(1) + 'w+';
  } else if (num >= 1000) {
    return Math.floor(num / 1000) + 'k+';
  } else {
    return num.toString();
  }
}

const Product = ({ product, idx }) => {
  const [visible, setVisible] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, idx * 150);
  }, []);

  const tags = {
    'super_factory': t('index.search.product.tags.superFactory'),
    'powerful_merchants': t('index.search.product.tags.powerfulMerchants'),
    'tp_member': t('index.search.product.tags.tpMember'),
    'yxmj': t('index.search.product.tags.yxmj'),
  }

  function translateProductTag(identity) {
    return tags[identity];
  }

  const productTags = (product.sellerIdentities || []).map(identity => translateProductTag(identity)).filter(tag => tag != null).slice(0, 3);
  return (
    <div className='product-banner' style={{ width: '50%', paddingLeft: '0.5rem', paddingRight: '0.5rem', ...(visible ? { opacity: 1, transform: 'scale(1)' } : {}) }}>
      <div className="flex-col bg-white cursor-pointer mb-8">
        <div className="flex-col justify-between">
          <div className="flex-col bg-cover" style={{ backgroundImage: `url(${product.imageUrl})`, width: '100%', height: '10rem' }}></div>
          <span className="text-black  font-normal line-clamp-2 whitespace-pre-line text-sm text-ellipsis" style={{ width: '100%', fontSize: '0.75rem', paddingLeft: '0.75rem', paddingRight: '0.75rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>{trans(product, 'subjectTrans', 'subject')}</span>
        </div>
        <div className="flex min-h-[18px]" style={{ paddingLeft: '0.75rem', paddingRight: '0.75rem' }} >
          {
            productTags && productTags.length > 0 ? productTags.map(tag => (
              <span className="px-[2px] border border-orange-500 overflow-hidden text-orange-500 text-xs font-normal whitespace-nowrap mr-[4px]" style={{ fontSize: '0.5rem' }}>{tag}</span>
            )) : (
              <span className="px-[2px] border border-orange-500 overflow-hidden text-orange-500 text-xs font-normal whitespace-nowrap mr-[4px]" style={{ fontSize: '0.5rem' }}>{tags['yxmj']}</span>
            )
          }
        </div>
        <div className="flex-row  justify-between" style={{ paddingLeft: '0.75rem', paddingRight: '0.75rem', marginTop: '0.5rem' }}>
          <span className=" text-black text-lg font-normal whitespace-nowrap" style={{ fontSize: '1rem' }}>{t("common.currency.symbol")}{product.priceInfo.price}</span>
          <div className="flex items-center" style={{ fontSize: '0.6rem' }}>
            <div className="font-normal whitespace-nowrap">
              <span className=" text-black">Rr</span>
              <span className=" text-black">:</span>
              <span className=" text-black ">45%</span>
            </div>
            <img className="h-[8px]" referrerPolicy="no-referrer" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng965299bffe139dd9ae1b0451c1e9ff2e970e65c2feedd5c9b924b6ec13f66bba" style={{ height: '0.25rem', width: '0.25rem' }} />
            <div className="font-normal whitespace-nowrap" style={{ marginLeft: '0.5rem' }}>
              <span className=" text-black">DSR</span>
              <span className=" text-black">:</span>
              <span className=" text-black">4.5</span>
            </div>
          </div>
        </div>
        <span className=" overflow-hidden text-gray-600 font-normal whitespace-nowrap" style={{ fontSize: '0.75rem', paddingLeft: '0.75rem', paddingRight: '0.75rem' }}>Sold&nbsp;{formatNumber(product.monthSold)}&nbsp;pairs&nbsp;in&nbsp;30&nbsp;days</span>
        <div className="flex">
          <span className=" block w-[16px] h-[16px] ml-[18px]"></span>
          <span className="block w-[16px] h-[16px]"></span>
        </div>
      </div>
    </div>
  )
}

const SearchOverlay = ({ pageData }) => {
  // const product = {
  //   sellerIdentities: ['super_factory'],
  //   productTags: ['goods'],
  //   subjectTrans: "Waffle Hoodie Women's Autumn New Loose Lazy Slimming All-match Thin Casual Jacket Zipper Cardigan",
  //   priceInfo: {
  //     price: '19.9'
  //   },
  //   monthSold: 3000,
  //   imageUrl: 'https://cbu01.alicdn.com/img/ibank/O1CN01iTkggs1Dxer2X0YLv_!!2214103390283-0-cib.jpg'
  // }

  React.useEffect(() => {
    window.document.body.style.overflow = 'hidden';

    return () => {
      window.document.body.style.overflow = 'scroll';
    }
  }, []);

  return (
    <div className='flex flex-wrap fixed search-overlay' style={{ background: '#E1E5E8', paddingLeft: '0.5rem', paddingTop: '1rem', paddingBottom: '1rem', paddingRight: '0.5rem', bottom: 0, left: 0, zIndex: 99, height: `calc(100vh - ${isWeChat()?10: 16}rem)`, width: '100vw', overflow: 'scroll' }}>
      {
        pageData && pageData.data && (
          pageData.data.map((product, idx) => <Product product={product} idx={idx} key={product.offerId} />)
        )
      }


      {/* <Product product={product} />
      <Product product={product} />
      <Product product={product} />
      <Product product={product} />
      <Product product={product} /> */}
    </div>
  )
}


const SearchInput = ({ onSearch, onSearchImageUpload, value, onClear }) => {
  const { t } = useTranslation()
  const [input, setInput] = React.useState(value);


  const handleInputChange = e => {
    setInput(e.target.value);
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      console.log('do validate');
      onSearch && onSearch(input);
      return;
    }
  }

  const handleUpload = async ({ file }) => {
    // 处理上传逻辑，可以在这里获取上传的文件信息
    console.log('Uploaded file:', file);
    // 这里可以根据实际需求处理上传后的操作
    const formData = new FormData();
    formData.append('file', file);

    try {
      // const res = await productApi.uploadSearchImage(formData);
      // onSearchImageUpload(res);
    } catch (error) {
      // 处理上传失败的情况
      message.error('上传失败');
      console.error('上传失败:', error);
    }

    // axios.post('/api/v1/upload_search_image', formData)
    //   .then(response => {

    //   })
    //   .catch(error => {

    //   });
  };

  const handleClear = () => {
    setInput('');
    onClear && onClear();
  }

  return (
    <div className="bg-white flex-row justify-end ml-auto mr-auto relative" style={{ height: '3rem', margin: 0, borderRadius: '1.75rem', overflow: 'hidden' }}>
      <input className="w-full h-full break-words text-black font-PingFangSC-Regular font-normal whitespace-nowrap outline-none border-none" type="text" style={{ fontSize: '0.75rem', paddingLeft: '1rem' }}
        value={input} placeholder={t('index.search.placeholder')} onChange={handleInputChange} onKeyDown={handleKeyPress} />
      {
        input && (
          <span className='absolute flex justify-center items-center' style={{ top: '0.5rem', right: '3rem', width: '2rem', height: '2rem' }} onClick={handleClear} >
            <img src="/images/clear.png" style={{ width: '1.5rem', height: '1.5rem' }} />
          </span>
        )
      }
      <div className='flex items-center justify-center' style={{ width: '4rem', background: '#0055CC', borderRadius: '1.75rem' }} onClick={() => onSearch && onSearch(input)}>
        <img
          className="w-[16px] h-[16px]"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge6013585618e443f63b9e303529910115e8f6df9e14287d1d429a8b26bbad3d5"
        />
      </div>
    </div>
  )
}

const SlideWindow = ({ cards }) => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  };


  return (
    <Slider {...settings} style={{ width: '100%', paddingLeft: '1rem', overflow: 'hidden', paddingBottom: '2rem' }} >
      {cards.map((card, index) => (
        <div className=''>
          <div key={index} className="bg-white flex-col mr-[12px] relative" style={{ width: '10rem' }}>
            {/* <div className="w-[364px] h-[364px] bg-[url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/fee8409d5737498eb5f20b72cddd95c3_mergeImage.png)] flex-col" /> */}
            <div className={`flex-col`} style={{ backgroundImage: `url(${card.imgUrl})`, backgroundSize: 'cover', width: '10rem', height: '10rem' }} />
            <div className={`flex bg-no-repeat bg-coverflex-row justify-center absolute`}
              style={{ backgroundImage: `url(${window.location.origin}/images/Sketch-Png6dced845a8ee89be3007ac90a7f44e19fb724746dfc0fe9768a19151061af798.png)`, top: 0, left: 0, width: '2.68rem', height: '2.31rem' }}>
              <div className="text-relative w-[22px] h-[37px] flex-col">
                <span className="break-words text-white text-xs font-bold text-left whitespace-nowrap leading-[16px] ml-[2px]">Top</span>
                <span className="break-words text-white font-bold text-left whitespace-nowrap flex justify-center">{index < 9 ? `0${index + 1}` : index + 1}</span>
              </div>
            </div>
            <div className="break-words text-black text-xs font-normal text-left line-clamp-2" style={{ marginTop: '0.5rem', width: '100%' }}>
              {trans(card, 'translateTitle', 'title')}
            </div>
            <div className="flex-col" style={{ marginTop: '0.75rem' }}>
              <div className='flex justify-between'>
                <span className="break-words text-base font-normal text-left whitespace-nowrap leading-[28px]" style={{ color: '#0055CC', fontSize: '1rem' }}>{t('common.currency.symbol')}{card.priceInfo.price}</span>
                <span className="h-[18px] break-words text-black text-xs font-normal text-left whitespace-nowrap leading-[18px] ml-[7px] mt-[5px]">DSR:4.5</span>
              </div>
              <div className='flex items-center'>
                <span className="h-[18px] break-words text-gray-600 text-xs font-normal text-center whitespace-nowrap leading-[18px] mt-[5px]">Sold&nbsp;{card.soldOut}&nbsp;pairs&nbsp;in&nbsp;30&nbsp;days</span>
              </div>

            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} flex rounded-full justify-center items-center opacity-15 hover:opacity-100`}
      style={{ ...style, background: '#0055CC', width: 40, height: 40, display: 'flex', right: '-50px', }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} flex rounded-full justify-center items-center  opacity-15 hover:opacity-100`}
      style={{ ...style, background: '#0055CC', width: 40, height: 40, display: 'flex', left: '-50px', }}
      onClick={onClick}
    />
  );
};


const RankHot = () => {
  const { i18n } = useTranslation();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    productApi.getRank({
      rankId: '1036995',
      rankType: 'hot',
      limit: 9,
      language: langParams[i18n.language],
    })
      .then(({ rankProductModels = [] }) => setData(rankProductModels))
      .catch(console.log);
  }, [i18n.language]);

  return (
    <SlideWindow cards={data} />
  )
}

const RankComplex = () => {
  const { i18n } = useTranslation();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    productApi.getRank({
      rankId: '126506005',
      rankType: 'complex',
      limit: 9,
      language: langParams[i18n.language],
    })
      .then(({ rankProductModels = [] }) => setData(rankProductModels))
      .catch(console.log);
  }, [i18n.language]);

  return (
    <SlideWindow cards={data} />
  )
}

const langParams = {
  'zh-CN': 'cn',
  'ru-RU': 'ru',
  'en-US': 'en',
  'es-ES': 'es',
  'en': 'en',
  'es': 'es',
  'ru': 'ru',
  'zh': 'cn',
  'cn': 'cn',
}

const RankPrice = () => {
  const { i18n } = useTranslation();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    productApi.getRank({
      rankId: '1032118',
      rankType: 'goodPrice',
      limit: 9,
      language: langParams[i18n.language],
    })
      .then(({ rankProductModels = [] }) => setData(rankProductModels))
      .catch(console.log);
  }, [i18n.language]);

  return (
    <SlideWindow cards={data} />
  )
}

const TabSwitcher = props => {
  const { t } = useTranslation();
  const [current, setCurrent] = React.useState(0);
  return (
    <Tabs selectedIndex={current} onSelect={setCurrent} style={{ paddingBottom: '3rem' }}>
      <TabList className="flex flex-col justify-between" style={{ marginBottom: '1.5rem' }}>
        <span className="font-alibabasans font-medium text-blue-600 leading-14 text-center" style={{ fontSize: '1rem', marginTop: '1.5rem', marginBottom: '1rem' }}>{t("index.discover.title")}</span>
        <div className="flex" style={{ marginLeft: '1rem', marginRight: '1rem' }}>
          <Tab className="" style={{ width: '33.3333%' }}>
            <div className="flex-row cursor-pointer items-center justify-center" style={{ height: '3rem', width: '100%' }}>
              <div className="flex-row justify-between">
                <span className="flex items-center justify-center" style={{ width: '1.75rem', height: '1.75rem' }}>
                  <StarOutlined style={{ fontSize: '18px' }} />
                </span>
                <span className="break-words text-md font-medium flex items-center" style={{ fontSize: '0.75rem' }}>{t("index.discover.tabs.top")}</span>
              </div>
            </div>
          </Tab>
          <Tab className="" style={{ width: '33.3333%' }}>
            <div className="flex-row cursor-pointer items-center justify-center" style={{ height: '3rem', width: '100%' }}>
              <div className="flex-row justify-between">
                <span className="flex items-center justify-center" style={{ width: '1.75rem', height: '1.75rem' }}>
                  <FireOutlined style={{ fontSize: '18px' }} />
                </span>
                <span className="break-words text-md font-medium flex items-center" style={{ fontSize: '0.75rem' }}>{t("index.discover.tabs.hot")}</span>
              </div>
            </div>
          </Tab>
          <Tab className="" style={{ width: '33.3333%' }}>
            <div className="flex-row cursor-pointer items-center justify-center" style={{ height: '3rem', width: '100%' }}>
              <div className="flex-row justify-between">
                <span className="flex items-center justify-center" style={{ width: '1.75rem', height: '1.75rem' }}>
                  <TagOutlined style={{ fontSize: '18px' }} />
                </span>
                <span className="break-words  text-md font-medium flex items-center" style={{ fontSize: '0.75rem' }}>{t("index.discover.tabs.price")}</span>
              </div>
            </div>
          </Tab>
        </div>
      </TabList>
      <TabPanel>
        <RankComplex />
      </TabPanel>
      <TabPanel>
        <RankHot />
      </TabPanel>
      <TabPanel>
        <RankPrice />
      </TabPanel>
    </Tabs>
  )
}

const SearchSection = props => {
  const { t, i18n } = useTranslation();
  const [pageData, setPageData] = React.useState({});
  const [effect, setEffect] = React.useState(false);
  const [searchParams, setSearchParams] = React.useState({});
  const [loaded, setLoaded] = React.useState(false);
  const [overlayShow, setOverlayShow] = React.useState(false);

  const handleSearch = keyword => {
    setSearchParams({
      ...searchParams,
      keyword,
      imageId: null,
    });
  }

  const handleSearchImage = ({ imageId }) => {
    if (!imageId) {
      return;
    }

    setSearchParams({
      ...searchParams,
      imageId,
      keyword: null,
    })
  }

  React.useEffect(() => {
    if (!searchParams.keyword && !searchParams.imageId) {
      return;
    }

    setEffect(true);
    setLoaded(false);
    setOverlayShow(true);
    if (searchParams.imageId) {
      productApi.searchImage({
        "beginPage": 0,
        "country": i18n.lanugage,
        // "categoryId": 1031861,
        "pageSize": 10,
        "imageId": searchParams.imageId,
        // "imageId": "1019208131918790770",
        // ...searchParams,
      })
        .then(pageData => {
          setPageData(pageData);
          setLoaded(true);
        })
    } else {
      productApi.search({
        "beginPage": 0,
        "country": i18n.lanugage,
        "pageSize": 10,
        ...searchParams,
      })
        .then(pageData => {
          setPageData(pageData);
          setLoaded(true);
        })
    }
  }, [searchParams, i18n.language])


  const handleClear = () => {
    setEffect(false);
    setOverlayShow(false);
    setLoaded(false);
    setSearchParams({})
  }

  React.useEffect(() => {

    if (overlayShow) {
      window.document.body.style.overflow = 'hidden';
      window.document.body.style.height = '100vh';
    } else {
      window.document.body.style.overflow = 'auto';
      window.document.body.style.height = 'auto';
    }

  }, [overlayShow])
  return (
    <>
      <div className="bg-no-repeat flex justify-center relative" style={{ backgroundImage: 'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e0625e36a25f4895b1ca8d11ee1eea09_mergeImage.png)', backgroundPosition: 'top center', backgroundSize: 'cover', height: '19rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', paddingTop: '4.75rem' }}>
        <div className={`${effect ? 'floatup-effect-mobile' : ''}`} style={{ zIndex: '2', width: '100%' }}>
          <div className='flex-col'>
            <span className={`${effect ? 'floatup-fadeIn-mobile break-words text-white font-AlibabaSans text-4 font-bold text-center whitespace-nowrap' : 'break-words text-white font-AlibabaSans text-4 font-bold text-center whitespace-nowrap'}`} style={{ marginBottom: '1.5rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
              {t("index.search.title")}
            </span>
            <SearchInput onSearch={handleSearch} onClear={handleClear} />
          </div>
        </div>
        <div className='bg-cover-overlay'></div>
      </div>
      {
        overlayShow && <SearchOverlay pageData={pageData} />
      }
    </>

  )
}

function isWeChat() {
  var ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') !== -1;
}


const LanguageOverlay = props => {
  const { t, i18n } = useTranslation();

  const changeLanguage = lang => {
    i18n.changeLanguage(lang);
  }

  const language = i18n.language;

  React.useEffect(() => {
    window.document.body.style.overflow = 'hidden';
    window.document.body.style.height = '100vh';

    return () => {
      window.document.body.style.overflow = 'scroll';
      window.document.body.style.height = 'auto';
    }
  }, []);
  return (
    <div className='fixed' style={{ background: '#fff', bottom: 0, zIndex: 99, width: '100vw', height: `calc(100vh - ${isWeChat()?4:9}rem)`, paddingTop: '1.5rem', paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1.5rem' }}>
      <span className="overflow-wrap-break-word text-rgba(0, 0, 0, 1)  font-AlibabaSans-Medium font-bold text-center whitespace-nowrap leading-[28px]" style={{ fontSize: '1rem' }}
      >{t('index.lang.title')}</span>
      <div className="flex-col">
        <div className='flex-col'>
          <div className="flex-row justify-between">
            <div className="bg-rgba(216, 216, 216, 0) flex-col items-center justify-center" style={{ width: '50%', height: '2.8rem' }}>
              <span className=" overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Regular font-normal text-left whitespace-nowrap leading-[25px] my-[12px] mx-[20px]" style={language === 'zh-CN' ? { color: '#0055CC' } : {}} onClick={() => changeLanguage('zh-CN')}>简体</span>
            </div>
       
            <div className="bg-rgba(216, 216, 216, 0) flex-col items-center justify-center" style={{ width: '50%', height: '2.8rem' }}>
              <span className=" overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Medium font-500 text-justify whitespace-nowrap leading-[25px] ml-[20px]" style={language === 'en-US' ? { color: '#0055CC' } : {}} onClick={() => changeLanguage('en-US')}>English</span>
            </div>
          </div>
          <div className="flex-row justify-between">
            <div className="bg-rgba(216, 216, 216, 0) flex-col items-center justify-center" style={{ width: '50%', height: '2.8rem' }}>
              <span className="overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Regular font-normal text-left whitespace-nowrap leading-[25px] my-[11px] mx-[20px]" style={language === 'ru-RU' ? { color: '#0055CC' } : {}} onClick={() => changeLanguage('ru-RU')}>Русский</span>
            </div>
            <div className="bg-no-repeat bg-cover flex-col items-center justify-center" style={{ width: '50%', height: '2.8rem' }}>
              <span className="overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Regular font-normal text-justify whitespace-nowrap leading-[25px] my-[10px] mx-[20px]" style={language === 'es-ES' ? { color: '#0055CC' } : {}} onClick={() => changeLanguage('es-ES')}>Lengua española</span>
            </div>
          </div>
        </div>
        <div style={{ height: '1px', width: '100%', background: '#eee', marginBottom: '1rem', marginTop: '1rem'}}></div>
        <div className='flex-col'>
          <div className="flex-row justify-between">
            <div className="bg-rgba(216, 216, 216, 0) flex-col items-center justify-center" style={{ width: '50%', height: '2.8rem' }}>
              <span className=" overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Regular font-normal text-left whitespace-nowrap leading-[25px] my-[12px] mx-[20px]" style={language === 'zh-CN' ? { color: '#0055CC' } : {}} >CNY</span>
            </div>
            <div className="bg-rgba(216, 216, 216, 0) flex-col items-center justify-center" style={{ width: '50%', height: '2.8rem' }}>
              <span className="overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Regular font-normal text-left whitespace-nowrap leading-[25px] my-[11px] mx-[20px]" style={language === 'en-US' ? { color: '#0055CC' } : {}} >USD</span>
            </div>
          </div>
          <div className="flex-row justify-between">
            <div className="bg-rgba(216, 216, 216, 0) flex-col items-center justify-center" style={{ width: '50%', height: '2.8rem' }}>
              <span className=" overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Medium font-500 text-justify whitespace-nowrap leading-[25px]" style={language === 'ru-RU' ? { color: '#0055CC' } : {}} >RU</span>
            </div>
            <div className="bg-no-repeat bg-cover flex-col items-center justify-center" style={{ width: '50%', height: '2.8rem' }}>
              <span className="overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Regular font-normal text-justify whitespace-nowrap leading-[25px] my-[10px] mx-[20px]" style={language === 'es-ES' ? { color: '#0055CC' } : {}} >EUR</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default props => {
  const { t, i18n } = useTranslation()
  const [langShow, setLangShow] = React.useState(false);

  React.useEffect(() => {
    document.documentElement.style.fontSize = '16px';
  },[])

  return (
    <div className="mobile-page flex-col">
      <div className='bg-no-repeat bg-cover flex-center flex-row relative px-4 justify-between items-center' style={{ background: '#0055CC', height: '3.75rem' }}>
        <div className='flex-row' >
          <img src="/images/logo.png" style={{ width: '8rem', objectFit: 'contain' }} />
        </div>
        <div className="flex">
          <div className="relative" onClick={() => setLangShow(true)}>
            <img src="/images/lang.png" alt="" style={{ width: '2.3rem', objectFit: 'contain' }} />
          </div>
          {
            langShow && (
              <div onClick={() => setLangShow(false)}>
                <img src="/images/close.png" alt="" style={{ width: '2.3rem', objectFit: 'contain' }} />
              </div>
            )
          }
        </div>
      </div>
      <SearchSection />
      <div className="flex-col">
        <TabSwitcher />
      </div>
      <div className="flex-col" style={{ background: '#0055CC', paddingBottom: '2.5rem' }}>
        <div className='flex-col'>
          <span className="break-words text-white font-AlibabaSans-Medium font-semibold text-left whitespace-nowrap flex justify-center" style={{ fontSize: '1rem', marginTop: '1.5rem', marginBottom: '1.5rem' }}
          >{t("index.solutions.title")}</span>
          <div className="flex-col">
            <div className="flex flex-col items-center">
              <img className="object-cover" src="/images/agence-olloweb-qfp4-Ud6-Fyg-unsplash.jpg" style={{ width: '20rem', height: '10rem', marginBottom: '1rem' }} />
              <div className="flex-col">
                <h5 className="overflow-wrap break-words text-white text-center font-AlibabaSans-Medium font-semibold whitespace-nowrap" style={{ marginBottom: '1rem', fontSize: '1rem' }}
                >{t("index.solutions.b2b.title")}</h5>
                <div className="flex-col" style={{ width: '20rem', marginLeft: 'auto', marginRight: 'auto' }}>
                  {
                    [0, 1, 2, 3, 4].map(idx => (
                      <p className="overflow-wrap break-words text-white text-left font-AlibabaSans-Regular font-normal leading-[25px]" style={{ fontSize: '0.75rem' }}
                      >{t(`index.solutions.b2b.b2b-c${idx}`)}</p>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center" style={{ marginTop: '2.5rem' }}>
              <img className="bg-no-repeat bg-cover" src="/images/jacques-dillies-jcav1-COVv-Oc-unsplash.jpg" style={{ width: '20rem', height: '10rem', marginBottom: '1rem' }} />
              <div className="flex-col">
                <h5 className="overflow-wrap break-words text-white text-center font-AlibabaSans-Medium font-semibold whitespace-nowrap " style={{ fontSize: '1rem' }}>{t("index.solutions.supply.title")}</h5>
                <span className="overflow-wrap break-words text-white text-left font-AlibabaSans-Regular font-normal leading-[25px]" style={{ fontSize: '0.75rem', width: '20rem', marginLeft: 'auto', marginRight: 'auto' }}
                >{t("index.solutions.supply.content")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-col bg-no-repeat bg-cover relative items-center" style={{ backgroundImage: 'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ae7465d603d84a36a63fafe299fb4ee6_mergeImage.png)' }}>
        <div className="flex-col" style={{ width: '20rem', marginTop: '3rem', zIndex: '2' }}>
          <span className="font-AlibabaSans  text-left break-words font-bold" style={{ color: '#fff', fontSize: '1rem', textAlign: 'center' }}
          >{t("index.overseas.title")}</span>
          <div className="flex-col" style={{ fontSize: '0.75rem', }}>
            <p className="font-AlibabaSans  text-left break-words mt-[24px]" style={{ color: '#fff' }}
            >{t("index.overseas.c0")}</p>
            <p className="font-AlibabaSans  text-left break-words mt-[12px]" style={{ color: '#fff' }}
            >{t("index.overseas.c1")}</p>
          </div>

          <div className="flex-col">
            <div className="flex-col items-center" style={{ marginTop: '2rem', marginBottom: '1rem' }}>
              <img
                className="label_8"
                referrerpolicy="no-referrer"
                src="/images/mail.png"
              />
            </div>
            <div className="flex justify-center" style={{ fontSize: '0.8rem', marginBottom: '3rem' }}>
              <span className="font-AlibabaSans leading-[31px] text-left font-bold" style={{ color: '#fff' }}>{t("index.overseas.contanct")}:</span>
              <span className="font-AlibabaSans leading-[31px] text-left font-bold ml-[8px]" style={{ color: '#fff' }}>hello@pintaback.com</span>
            </div>
          </div>
        </div>
        <div className='bg-cover-overlay'></div>
      </div>
      <div className="flex-col" style={{ background: '#E1E5E8', paddingLeft: '1.5rem', paddingRight: '1.5rem', paddingBottom: '1.5rem' }}>
        <span className="font-AlibabaSans font-medium  text-black text-center " style={{ fontSize: '1rem', marginTop: '1.5rem', marginBottom: '1rem' }}>{t("index.case.title")}</span>
        <div className="flex-col">
          <div className="flex-col bg-white items-center" style={{ paddingBottom: '1rem' }}>
            <div className="flex-col bg-no-repeat bg-cover" style={{ backgroundImage: 'url(/images/campaign-creators-g-Msn-Xq-ILjp4-unsplash.jpg)', width: '100%', height: '12rem', marginBottom: '0.75rem' }}></div>
            <div className="flex-row w-full items-center pl-[16px] pr-[16px]">
              <div className=" bg-gray-300 rounded-full flex-col justify-center items-center" style={{ background: '#0055CC', width: '1.75rem', height: '1.75rem', marginRight: '0.5rem' }}>
                <span className=" ml-[0px] mt-[0px]" style={{ fontSize: '0.5rem', color: '#fff' }}>USA</span>
              </div>
              <span className="font-AlibabaSans font-medium text-base text-black leading-31" style={{ fontSize: '0.75rem' }}>{t("index.case.usa.title")}</span>
            </div>
            <span className="font-AlibabaSans text-base text-black  w-full break-words pl-[16px] pr-[16px]" style={{ fontSize: '0.75rem', marginTop: '1rem' }}
            >{t("index.case.usa.content")}</span>
          </div>
          <div className="flex-col bg-white" style={{ marginTop: '1rem', paddingBottom: '1rem' }} >
            <div className="bg-gray-600 flex-col mb-[16px] bg-no-repeat bg-cover" style={{ backgroundImage: 'url(/images/headway-5-Qg-Iuu-Bx-Kw-M-unsplash.jpg)', width: '100%', height: '12rem', marginBottom: '0.75rem' }}></div>
            <div className="flex-row w-full items-center pl-[16px] pr-[16px]">
              <div className="w-[54px] h-[54px] bg-gray-300 rounded-full flex-col justify-center items-center" style={{ background: '#0055CC', width: '1.75rem', height: '1.75rem', marginRight: '0.5rem' }}>
                <span className="ml-[0px] mt-[0px]" style={{ fontSize: '0.5rem', color: '#fff' }}>Korea</span>
              </div>
              <span className="font-AlibabaSans font-medium text-base text-black leading-31" style={{ fontSize: '0.75rem' }}>{t("index.case.korea.title")}</span>
            </div>
            <span className="font-AlibabaSans text-base text-black  w-full break-words pl-[16px] pr-[16px]" style={{ fontSize: '0.75rem', marginTop: '1rem' }}
            >{t("index.case.korea.content")}</span>
          </div>
        </div>
      </div>
      <div className="flex-col" style={{ background: '#333', paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '2rem', paddingTop: '2rem' }}>
        <div className='flex justify-between'>
          <div className="flex-col">
            <span className="font-AlibabaSans font-medium text-white text-base leading-25 mb-[16px]">PINTABACK co.,Ltd</span>
            <span className="font-AlibabaSans font-normal text-white leading-18 mb-[8px]" style={{ fontSize: '0.75rem' }}>{t("index.footer.menu.home")}</span>
            <span className="font-AlibabaSans font-normal text-white leading-18 mb-[8px]" style={{ fontSize: '0.75rem' }}>{t("index.footer.menu.search")}</span>
            <span className="font-AlibabaSans font-normal text-white leading-18 mb-[8px]" style={{ fontSize: '0.75rem' }}>{t("index.footer.menu.services")}</span>
            <span className="font-AlibabaSans font-normal text-white leading-18 mb-[8px]" style={{ fontSize: '0.75rem' }}>{t("index.footer.menu.contact")}</span>
            <span className="font-AlibabaSans font-normal text-white leading-18 mb-[8px]" style={{ fontSize: '0.75rem' }}>{t("index.footer.menu.case")}</span>
          </div>
          <div className=" flex-col">
            <div className="flex-col justify-between">
              <span className="font-AlibabaSans text-white text-sm leading-25 text-left mb-[16px]" style={{ fontSize: '1rem' }}>{t("index.footer.more.more")}</span>
              <span className="font-AlibabaSans font-normal text-white leading-18 mb-[24px]" style={{ fontSize: '0.75rem' }}>{t("index.footer.more.about")}</span>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '1.5rem' }}>
          <img
            className=""
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2145cf28f06a843c60b42a1130e74d4551d9844cf9ab5071ecafa96c5867f4cf"
          />
        </div>
        <div className='flex items-center justify-between' style={{ marginTop: '1.5rem' }}>
          <span className="font-AlibabaSans font-medium text-white text-base leading-25 text-left">{t("index.footer.more.follow")}</span>
          <div className="flex-row">
            <img
              referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng99dc73de7f8c016e5b44378d0f1668b048f9a8f6cefa6c02e9287849cac22738"
              style={{ width: '1rem', height: '1rem' }}
            />
            <img
              referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0ab8e9516bd7d7f6ec11e40acec26b7daeb613572aeb2dedc588badeb24cb9ba"
              style={{ marginLeft: '1.5rem', marginRight: '1.5rem', width: '1rem', height: '1rem' }}
            />
            <div className="flex-col" style={{ width: '1rem', height: '1rem' }}>
              <div className="rounded-md bg-cover bg-center bg-no-repeat w-[16px] h-[16px] m-[2px] flex-col" style={{ backgroundImage: `url('https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/98ddc4d957364035b3d65a2518050cfe_mergeImage.png')` }}></div>
            </div>
          </div>
        </div>
        <div className="flex justify-center" style={{ marginTop: '2.5rem' }}>
          {/* <div className="w-[107px] h-[36px]flex-col justify-center items-center self-end">
            <img src="/images/logo_w.png" />
          </div> */}
          <span className="font-normal text-white flex justify-center leading-18 self-end mt-auto opacity-30" style={{ color: '#fff', fontSize: '0.8rem' }}
          >Copyright&nbsp;©&nbsp;2024&nbsp;PINTABACK&nbsp;All&nbsp;Rights&nbsp;Reserved</span>
        </div>
      </div>
      {
        langShow &&  <LanguageOverlay />
      }
    </div>
  )
}