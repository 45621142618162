import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const SaleOrderProductStatus = ({ status }) => {
  const getStatusText = (status) => {
    switch (status) {
      case 'NEW':
        return '已创建';
      case 'PENDING':
        return '待处理';
      case 'PURCHASE_PENDING':
        return '待采购';
      case 'PURCHASING':
        return '采购中';
      case 'SHIPPED':
        return '已发货';
      case 'DELIVERED':
        return '已送达';
      case 'COMPLETED':
        return '已完成';
      case 'CANCELLED':
        return '已取消';
      case 'REFUNDED':
        return '已退款';
      case 'RETURNED':
        return '已退货';
      case 'FAILED':
        return '已失败';
      default:
        return '未知状态';
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};

export default SaleOrderProductStatus;