import React, { useState, useEffect } from 'react';
import { Table, Button, Drawer, Form, Input, Space, Breadcrumb, TreeSelect, Modal, message } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import shopApi from '../../servapi/shop';
import categoryApi from '../../servapi/category';
import { useTranslation } from 'react-i18next';

const ShopCategoryForm = ({ visible, onClose, editingShopCategory, categories, onSave }) => {
  const [treeData, setTreeData] = useState([]);
  // const [selectedCategories, setSelectedCategories] = useState([1]);

  useEffect(() => {
    // 构建树形数据结构
    const buildTree = (data, parentId = 0) => {
      return data
        .filter(category => category.parentCategoryId === parentId)
        .map(category => ({
          title: category.description?.name,
          value: category.id,
          key: category.id,
          children: buildTree(data, category.id),
        }));
    };

    const treeStructure = buildTree(categories);
    setTreeData(treeStructure);
  }, [categories]);

  // 递归查找父类目ID列表
  const findAllParentIds = (categoryId, categoryList) => {
    const category = categoryList.find(cat => cat.id === categoryId);
    if (category && category.parentCategoryId !== 0) {
      return [category.parentCategoryId, ...findAllParentIds(category.parentCategoryId, categoryList)];
    }
    return [];
  };

  // // 处理类别选择变更
  // const handleCategoryChange = (values) => {
  //   let newSelectedCategories = [...values];

  //   // 对每个选中的子类目，递归查找其祖先类目并添加到已选中列表中
  //   values.forEach(value => {
  //     const parentIds = findAllParentIds(value, categories);
  //     parentIds.forEach(parentId => {
  //       if (!newSelectedCategories.includes(parentId)) {
  //         newSelectedCategories.push(parentId);
  //       }
  //     });
  //   });

  //   setSelectedCategories(newSelectedCategories);
  // };

  // // 阻止父类目选中子类目
  // const handleCheck = (value, label, extra) => {
  //   const { checkedNodesPositions } = extra;
  //   let newSelected = [];

  //   // 父类目不会自动选中子类目，保持独立
  //   checkedNodesPositions && checkedNodesPositions.forEach(node => {
  //     const nodeValue = node.node.value;
  //     if (!newSelected.includes(nodeValue)) {
  //       newSelected.push(nodeValue);
  //     }
  //   });

  //   // 处理手动选中子类目的父类目
  //   handleCategoryChange([]);
  // };

  // const handleCategoryChange = value => {
  //   console.log(value, 'hand.....');
  //   setSelectedCategories(value)
  //   // setSelectedCategories([
  //   //   1
  //   // ])
  // }

  console.log(JSON.stringify(treeData), 'selectedCategories')
  return (
    <Modal
      title={editingShopCategory ? '编辑类目' : '创建类目'}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={520}
    >
      <Form onFinish={onSave} initialValues={editingShopCategory || {}} key={editingShopCategory}>
        <Form.Item name="name" label="类目名称" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="slug" label="网址别名" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="categoryIds" label="选择类目" rules={[{ required: false }]}>
          <TreeSelect
            treeData={treeData}
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            treeCheckable
            treeCheckStrictly
            placeholder="关联1688类目"
            allowClear
            showSearch
            treeDefaultExpandAll
            // onChange={handleCategoryChange} // 使用自定义的 check 处理逻辑
            style={{ width: '100%' }}
            filterTreeNode={(inputValue, treeNode) => {
              if (treeNode && treeNode.title) {
                return treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
              }
              
              return false;
            }
              
            }
          />
        </Form.Item>
        <Form.Item name="description" label="描述">
          <Input.TextArea />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          保存
        </Button>
      </Form>
    </Modal>

  );
};

const TestTree = () => {
  const [value, setValue] = useState([1, 3, 2]);
  const treeData = [
    {
      "title": "Women's Clothing",
      "value": 1,
      "key": 1,
      "children": [
        {
          "title": "Women's T-shirt",
          "value": 2,
          "key": 2,
          "children": [

          ]
        },
        {
          "title": "Dress",
          "value": 8,
          "key": 8,
          "children": [

          ]
        },
        {
          "title": "fashion casual suit",
          "value": 12,
          "key": 12,
          "children": [

          ]
        },
        {
          "title": "Blouse",
          "value": 13,
          "key": 13,
          "children": [

          ]
        },
        {
          "title": "Women's casual pants",
          "value": 14,
          "key": 14,
          "children": [
            {
              "title": "Casual Pants",
              "value": 15,
              "key": 15,
              "children": [

              ]
            }
          ]
        }
      ]
    },
    {
      "title": "Food Drinkers",
      "value": 3,
      "key": 3,
      "children": [
        {
          "title": "Chocolate",
          "value": 4,
          "key": 4,
          "children": [

          ]
        }
      ]
    },
    {
      "title": "Instrumentation",
      "value": 5,
      "key": 5,
      "children": [
        {
          "title": "analytical instrument",
          "value": 6,
          "key": 6,
          "children": [
            {
              "title": "water quality analyzer",
              "value": 7,
              "key": 7,
              "children": [

              ]
            }
          ]
        }
      ]
    },
    {
      "title": "Household appliances",
      "value": 9,
      "key": 9,
      "children": [
        {
          "title": "Living appliances",
          "value": 10,
          "key": 10,
          "children": [
            {
              "title": "household sweeper",
              "value": 11,
              "key": 11,
              "children": [

              ]
            }
          ]
        }
      ]
    },
    {
      "title": "Machinery and industry equipment",
      "value": 16,
      "key": 16,
      "children": [
        {
          "title": "Fasteners, fittings",
          "value": 17,
          "key": 17,
          "children": [
            {
              "title": "Screw",
              "value": 18,
              "key": 18,
              "children": [

              ]
            }
          ]
        }
      ]
    },
    {
      "title": "Office, culture",
      "value": 19,
      "key": 19,
      "children": [
        {
          "title": "Learning Stationery",
          "value": 20,
          "key": 20,
          "children": [
            {
              "title": "Pencil sharpener, pencil sharpener",
              "value": 21,
              "key": 21,
              "children": [

              ]
            }
          ]
        }
      ]
    }
  ];

  const tProps = {
    treeData,
    value,
    // onChange,
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_ALL,
    treeCheckStrictly: true,
    placeholder: 'Please select',
    style: {
      width: '100%',
    },
  };
  return <TreeSelect {...tProps} />;
}


const ShopProductCategoryManager = ({ shopId = 2 }) => {
  const [shopCategories, setShopCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editingShopCategory, setEditingShopCategory] = useState(null);
  const [parentId, setParentId] = useState(); // From route parameters
  const [categoryPaths, setCategoryPaths] = useState([]);
  const [categories, setCategories] = useState([]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    // Fetch shop categories based on parentId
    fetchShopCategories(shopId, parentId);
    fetchCategoryPaths(shopId, parentId);
  }, [shopId, parentId]);

  useEffect(() => {
    categoryApi.getAllCategories()
      .then(setCategories)
      .catch(console.log);
  }, [i18n.language])

  const fetchShopCategories = async (shopId, parentId) => {
    const categories = await shopApi.getShopCategories(shopId, { parentId });
    setShopCategories(categories);
  }

  const fetchCategoryPaths = async (shopId, parentId) => {
    if (!parentId) {
      setCategoryPaths([]);
      return;
    }

    const paths = [];
    let parentCategory;
    while (parentId && parentId > 0) {
      parentCategory = await shopApi.getShopCategory(parentId);
      paths.push(parentCategory);
      parentId = parentCategory.parentId;
    }

    setCategoryPaths(paths.reverse());
  }

  const showDrawer = (shopCategory = null) => {
    if (shopCategory != null) {
      setEditingShopCategory({
        id: shopCategory.id,
        slug: shopCategory.code,
        name: shopCategory.description?.name,
        categoryIds: shopCategory.categoryIds,
      });
    } else {
      setEditingShopCategory(null);
    }
    setVisible(true);
  };

  const handleSave = async (values) => {
    let parentId;
    const categoryIds = (values.categoryIds || []).map(c => c.value);

    if (categoryPaths.length > 0) {
      parentId = categoryPaths[categoryPaths.length - 1].id;
    }

    if (editingShopCategory) {
      await shopApi.updateShopCategory(shopId, editingShopCategory.id, {
        ...editingShopCategory,
        ...values,
        categoryIds,
      });
    } else {
      await shopApi.createShopCategory(shopId, {
        ...values,
        categoryIds,
        parentId,
      });
    }
    handleDrawerClose();
    setEditingShopCategory(null);
    fetchShopCategories(shopId, parentId);
  };

  // Dynamically generate page title and operations based on parentId

  const handleSetParentId = record => {
    setParentId(record.id);
  }

  console.log(shopCategories, 'categories...');

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (<span>{record.description?.name}</span>)
    },
    {
      title: '网址别名',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '状态',
      dataIndex: 'code',
      key: 'code',
      render: (_, record) => <span>{record.wpProductCategoryId ? '已同步': '待同步'}</span>
    },
    {
      title: '操作',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => showDrawer(record)}>编辑</Button>
          {
            record.depth < 3 && (
              <Button type="link" onClick={() => handleSetParentId(record)}>
                查看子类目
              </Button>
            )
          }
        </>
      ),
    },
  ];

  const handleDrawerClose = () => {
    setVisible(false);
    setEditingShopCategory(null);
  }

  const handleResyncShopCategories = async () => {
    const hide = message.loading('正在同步店铺类目', 0);
    const resp = await shopApi.resyncShopCategories(shopId);
    hide();
    message.success('店铺类目同步完成');

    fetchShopCategories(shopId, parentId)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', marginBottom: 16, marginTop: 24 }}>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => setParentId(null)}>
            <span className="cursor-pointer">
              <HomeOutlined />
            </span>
          </Breadcrumb.Item>
          {
            categoryPaths.reverse().map(categoryPath => (
              <Breadcrumb.Item key={categoryPath.id} onClick={() => setParentId(categoryPath.id)}>
                <span>{categoryPath.description?.name}</span>
              </Breadcrumb.Item>
            ))
          }
        </Breadcrumb>
        <div>
          <Button type="primary" onClick={handleResyncShopCategories} style={{ marginRight: 8 }}>同步类目</Button>
          <Button type="primary" onClick={() => showDrawer()}>创建类目</Button>
        </div>
      </div>
      <Table columns={columns} dataSource={shopCategories || []} rowKey="id" />
      {
        visible && <ShopCategoryForm visible={visible} editingShopCategory={editingShopCategory} categories={categories} onSave={handleSave} onClose={() => setVisible(false)} />
      }
    </div>
  );
};

export default ShopProductCategoryManager;
