import { create } from "../util/request";
import i18n from 'i18next';
import qs from 'qs';

const api = create({ baseURL: ''})

const langParams = {
  'zh-CN': 'cn',
  'ru-RU': 'ru',
  'en-US': 'en',
  'es-ES': 'es',
  'en': 'en',
  'es': 'es',
  'ru': 'ru',
  'zh': 'cn',
  'cn': 'cn',
}

class ProductApi {

  async search(params) {

    console.log(i18n, 'i18n.currentLanguage')
    return await api.post('/api/v1/search', {
      ...params,
      country: langParams[i18n.language],
    });
  }
  
  async searchImage(params) {
    return await api.post('/api/v1/search_image', {
      ...params,
      country: langParams[i18n.language],
    });
  }

  async uploadSearchImage(params) {
    return await api.post('/api/v1/upload_search_image', params);
  }

  async recommend(params) {
    return await api.post('/api/v1/recommend', {
      ...params,
      country: langParams[i18n.language],
    });
  }

  async getProductDetail(params) {
    return await api.post('/api/v1/product_get', {
      ...params,
      country: langParams[i18n.language],
    });
  }

  async getSearchSN(params) {
    return await api.post('/api/v1/search_sn', {
      ...params,
      country: i18n.language,
    });
  }

  async getRank(params) {
    return await api.post('/api/v1/ranks', {
      ...params,
      country: langParams[i18n.language],
    });
  }

  async createProduct(params) {
    return await api.post('/api/v1/woo_product', params);
  }

  async createSpuProduct(params) {
    return await api.post('/api/v1/spu_product', params);
  }

  async getSpuProducts(params) {
    return await api.get(`/api/v1/spu_products?${qs.stringify(params)}`)
  }

  async pushSpuProducts(params) {
    return await api.post('/api/v1/spu_product/push', params);
  }
}

const productApi = new ProductApi();
export default productApi;