import React, { useState, useEffect } from 'react';
import { Card, Button, Descriptions, Divider, DatePicker, Table, Tag, Typography, Row, Col, Select, Input, message, Pagination, Checkbox, Popover, InputNumber } from 'antd';
import { FileTextOutlined, ClockCircleOutlined, UserOutlined, FormOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import './SaleOrderList.less';
import saleOrderApi from '../servapi/sale_order';
import shopApi from '../servapi/shop';
import { useTranslation } from 'react-i18next';
import SaleOrderProductStatus from './component/status/SaleOrderProductStatus';
import CreateCustomSaleOrderOverlay from './component/CreateCustomSaleOrderOverlay';
import { constructAddress } from '../util/addr';
import purchaseOrderApi from '../servapi/purchase_order';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getCurrencySymbol } from '../util/currency';

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const orderData = {
  orderTime: '2024/07/16 00:02:41',
  orderNumber: 'M172105936196300001',
  store: 'wpmi',
  orderNotes: 'N/A',
  deliveryAddress: 'Nicolas, Kuo, softlight0629@gmail.com, Sweden, 哥特兰岛, 杭州市滨江区, 325000, 浙新小区',
  products: [
    {
      key: '4892938257804',
      image: 'https://cbu01.alicdn.com/img/ibank/O1CN01bMVvI01S2bezQDpM0_!!2210898012189-0-cib.jpg',
      link: 'https://detail.1688.com/offer/642154137407.html',
      description: '德国进口RitterSport瑞特斯波德巧克力运动排块牛奶夹心黑巧克力',
      details: '全榛子白巧克力100g',
      price: '10.50',
      quantity: 1
    },
    // Add more product objects as needed
  ]
};
function formatDate(dateString) {
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}



const SaleOrderStatusText = ({ status }) => {
  const { t } = useTranslation();
  const getStatusText = (status) => {
    switch (status) {
      case 'NEW':
        return t("status.saleOrderStatus.NEW");
      case 'PENDING_CONFIRM':
        return t("status.saleOrderStatus.PENDING_CONFIRM");
      case 'CONFIRMED':
        return t("status.saleOrderStatus.CONFIRMED");
      case 'PROCESSING':
        return t("status.saleOrderStatus.PROCESSING");
      case 'COMPLETED':
        return t("status.saleOrderStatus.COMPLETED");
      case 'CANCELLED':
        return t("status.saleOrderStatus.CANCELLED");
      case 'ON_HOLD':
        return t("status.saleOrderStatus.ON_HOLD");
      case 'REFUNDED':
        return t("status.saleOrderStatus.REFUNDED");
      case 'RETURNED':
        return t("status.saleOrderStatus.RETURNED");
      case 'FAILED':
        return t("status.saleOrderStatus.FAILED");
      default:
        return t("status.saleOrderStatus.UNKNOW");
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};

const OrderDetails = ({ orderDetail, onConfirm, onConfirmPurchase }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const [extraFeeChecked, setExtraFeeChecked] = useState(orderDetail.feePay);
  const [extraFee, setExtraFee] = useState(orderDetail.feePayAmount || 0);
  const [extraFeeNote, setExtraFeeNote] = useState(orderDetail.feePayNote || '');
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const handleCheckboxChange = async (e) => {

    if (e.target.checked) {
      setExtraFeeChecked(e.target.checked);
      setIsPopoverVisible(true);
    } else {
      const hide = message.loading('正在更新额外费用', 0);
      const resp = await saleOrderApi.updateSaleOrderFeePay(orderDetail.shop.id, orderDetail.id, {
        feePay: false,
        feePayAmount: 0,
      });
      setExtraFeeChecked(e.target.checked);
      setExtraFee(0)
      console.log(resp);
      hide();
      message.success('额外费用已取消');
    }
  };

  const handleFeeChange = (e) => {
    setExtraFee(e);
  };

  const handleFeeNoteChange = e => {
    setExtraFeeNote(e.target.value);
  }

  const handleConfirm = async () => {
    // 在这里处理保存费用的逻辑
    // console.log('费用已保存:', extraFee);
    const hide = message.loading('正在更新额外费用', 0);
    const resp = await saleOrderApi.updateSaleOrderFeePay(orderDetail.shop.id, orderDetail.id, {
      feePay: extraFeeChecked,
      feePayAmount: extraFee,
      feePayNote: extraFeeNote,
    })

    console.log(resp);
    hide();
    message.success('额外费用已更新');
    setIsPopoverVisible(false);
  };

  const handleCancel = () => {
    setIsPopoverVisible(false);
  };


  const columns = [
    {
      title:t("shopOrders.orderDetail.table.product"),
      dataIndex: 'description',
      key: 'description',
      width: '25%',
      render: (text, record) => (
        <div className="d-flex items-start" style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ float: 'left', width: '60px', height: '60px', marginRight: '16px' }}>
            <img src={record.productImage} alt="" style={{ width: '100%', height: '100%', borderRadius: '0px', border: '1px solid #eee' }} />
          </div>
          <div style={{ maxWidth: 520 }}>
            <Link to={`/product/detail/${record.outProductId}`} target="_blank">
              <Text ellipsis={{ rows: 2, expandable: false }}><span>{record.productName}</span></Text>
            </Link>
            {/* <Text ellipsis={{ rows: 2, expandable: false, width: 320 }}>
              <a href={record.productLink} target="_blank" rel="noopener noreferrer">{record.productName}</a>
            </Text> */}
          </div>
        </div>
      )
    },
    {
      title: t("shopOrders.orderDetail.table.sku"),
      dataIndex: 'skuCode',
      key: 'skuCode',
      width: '20%',
    },
    {
      title: t("shopOrders.orderDetail.table.price"),
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      render: (text, record) => (
        <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>{getCurrencySymbol(orderDetail.currency)}{record.price}</span>
      )
    },
    {
      title: t("shopOrders.orderDetail.table.quantity"),
      dataIndex: 'quantity',
      key: 'quantity',
      width: '10%',
      render: (text, record) => (
        <span>{record.productQuantity}</span>
      )
    },
    {
      title: t("shopOrders.orderDetail.table.status"),
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (text, record) => (
        <span><SaleOrderProductStatus status={record.status} /></span>
      )
    },
    {
      title: t("shopOrders.orderDetail.table.details"),
      dataIndex: 'details',
      key: 'details',
      width: '15%',
      render: text => (
        <span>
          <Text ellipsis={{ rows: 3, expandable: false }} className="ant-typography-secondary"><Tag>物流查询</Tag></Text>
        </span>

      )
    },
  ];

  const dataSource = showAll ? orderDetail.orderProducts : orderDetail.orderProducts.slice(0, 3);

  const popoverContent = (
    <div>
      <InputNumber
        placeholder="请输入费用金额"
        value={extraFee}
        onChange={handleFeeChange}
        prefix={getCurrencySymbol(orderDetail.currency)}
        min={0}
        step={0.01}
        style={{ width: 80, marginBottom: 8 }}
      />
      <div>
        <Input
          placeholder="请输入费用备注"
          value={extraFeeNote}
          style={{ width: 160 }}
          onChange={handleFeeNoteChange}
        />
      </div>
      <div style={{ textAlign: 'right', marginTop: 12 }}>
        <Button size="small" onClick={handleCancel} style={{ marginRight: 8 }}>
          取消
        </Button>
        <Button type="primary" size="small" onClick={handleConfirm}>
          确认
        </Button>
      </div>
    </div>
  );

  return (
    <Card style={{ marginTop: 16, minHeight: 200 }} bodyStyle={{ padding: 0 }}>
      <Card bodyStyle={{ padding: 0 }}>
        <div className="lkVn3iOw">
          <Card bordered style={{ border: 0 }}
          >
            <Card.Meta
              style={{ display: 'flex' }}
              title={
                <div className="aHPEc5kL" style={{ display: 'flex' }}>
                  <div style={{ marginRight: 8 }}>
                    <strong>{formatDate(orderDetail.createdTime)}</strong>
                  </div>
                  <div style={{ marginRight: 8 }}>{t("shopOrders.orderDetail.orderNo")}：&nbsp;{orderDetail.orderId}</div>
                  {/* <div><Tag color="blue">打开</Tag></div> */}
                </div>
              }
              description={
                <div>
                  {/* <Button style={{ width: 100 }}>编 辑</Button> */}
                  {/* {
                    orderDetail.orderStatus !== 'PENDING_CONFIRM' && (
                      <Button style={{ margin: '0 8px' }}>取 消</Button>
                    )
                  } */}
                  {
                    orderDetail.orderStatus === 'PENDING_CONFIRM' && orderDetail.orderType === 'SALE_ORDER' && (
                      <>
                        <Button style={{ margin: '0 8px' }}>删 除</Button>
                        <Button type="primary" onClick={() => onConfirm(orderDetail)}>确 认</Button>
                      </>
                    )
                  }
                  {
                    orderDetail.orderStatus === 'PENDING_CONFIRM' && orderDetail.orderType === 'MANUAL_ORDER' && (
                      <>
                        <Button style={{ margin: '0 8px' }}>删 除</Button>
                        <Button type="primary" onClick={() => onConfirmPurchase(orderDetail)}>确 认</Button>
                      </>
                    )
                  }

                </div>
              }
            />
          </Card>
          <div style={{ padding: '16px 16px 0', background: '#FAFAFA', borderTop: '1px solid #E8E8E8' }}>
            <Descriptions column={2}>
              <Descriptions.Item label={t("shopOrders.orderDetail.shop")} span={1}>{orderDetail.shop.shopName}</Descriptions.Item>
              <Descriptions.Item label={t("shopOrders.orderDetail.orderNote")} span={3}>{orderDetail.orderNote}</Descriptions.Item>
            </Descriptions>
            <Descriptions column={10}>
              {/* <Descriptions.Item label={t("shopOrders.orderDetail.deliveryAddr")}span={5}>{constructAddress(orderDetail)}</Descriptions.Item> */}
              <Descriptions.Item label={t("shopOrders.orderDetail.shopOrder")}span={5}><Link to={orderDetail.wpOrderLink} target='_blank'>{orderDetail.wpOrderNo}</Link></Descriptions.Item>
              <Descriptions.Item label={t("shopOrders.orderDetail.orderStatus")} span={5}>
                <SaleOrderStatusText status={orderDetail.orderStatus} />
              </Descriptions.Item>
            </Descriptions>
          </div>
          <Divider style={{ margin: 0 }} />
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            rowKey="key"
          />
          {
            orderDetail.orderProducts.length > 3 && (
              <div style={{ textAlign: 'center', }}>
                <Button type="link" onClick={() => setShowAll(!showAll)}>
                  {showAll ? <UpOutlined /> : <DownOutlined />}
                  {showAll ? '隐藏' : `还有${orderDetail.orderProducts.length - 3}个商品，查看所有`}
                </Button>
              </div>
            )
          }
          <div style={{ paddingRight: 24 }}>
            <Descriptions size="small" column={1} style={{ height: '100%', paddingTop: 8, paddingLeft: 8, }}>
              <Descriptions.Item label={t("shopOrders.orderDetail.summary.productNum")} style={{ float: 'right' }}>
                <Text strong style={{ color: 'blue' }}>
                  <span>
                    {orderDetail.orderProducts.length}
                  </span>
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label={t("shopOrders.orderDetail.summary.orderTotal")} style={{ float: 'right' }}>
                <Text strong style={{ color: 'blue' }}>
                  <span>{getCurrencySymbol(orderDetail.currency)}{orderDetail.orderTotal}</span>
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label={t("shopOrders.orderDetail.summary.feePayment")} style={{ float: 'right' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox checked={extraFeeChecked} onChange={handleCheckboxChange}>
                  </Checkbox>
                  {extraFeeChecked && (
                    <Popover
                      content={popoverContent}
                      title="额外费用金额"
                      trigger="click"
                      visible={isPopoverVisible}
                      onVisibleChange={setIsPopoverVisible}
                    >
                      <Text strong style={{ color: 'blue', marginLeft: 8, cursor: 'pointer' }}>
                        {getCurrencySymbol(orderDetail.currency)}{extraFee}
                      </Text>
                    </Popover>
                  )}
                </div>
              </Descriptions.Item>
              <Descriptions.Item label={t("shopOrders.orderDetail.summary.totalAmount")} style={{ float: 'right' }}>
                <Text strong style={{ color: 'blue' }}>
                  <span>{getCurrencySymbol(orderDetail.currency)}{orderDetail.orderTotal + extraFee}</span>
                </Text>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </Card>
    </Card>
  );
};


const SearchOrders = ({ onSync, onSearch, onReset, onSetShop }) => {
  // 定义每个查询条件的状态
  const { t } = useTranslation();
  const [shopId, setShopId] = useState(null);
  const [orderOrProductName, setOrderOrProductName] = useState('');
  const [orderSource, setOrderSource] = useState(null);
  const [recipientName, setRecipientName] = useState('');
  const [status, setStatus] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  const [shops, setShops] = useState([]);

  useEffect(() => {
    shopApi.getShopList()
      .then(shops => {
        setShops(shops);
        handleSetShopId(shops[0].id);
      })
      .catch(console.log);
  }, []);


  // 重置表单
  const resetFilters = () => {
    setShopId(null);
    setOrderOrProductName('');
    setOrderSource(null);
    setRecipientName('');
    setStatus(null);
    setDateRange([]);

    onReset();
  };

  // 处理搜索逻辑
  const handleSearch = () => {
    const searchCriteria = {
      shopId,
      orderOrProductName,
      orderSource,
      recipientName,
      status,
      startDate: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null,
      endDate: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : null,
    };
    onSearch(searchCriteria); // 将查询条件传递给父组件或 API 调用
  };

  const ranges = {
    '最近3天': [moment().subtract(3, 'days'), moment()],
    '最近一周': [moment().subtract(7, 'days'), moment()],
    '最近一个月': [moment().subtract(1, 'months'), moment()],
  };

  const statuses = {
    "NEW": "已创建",
    "PENDING_CONFIRM": "待确认",
    "CONFIRMED": "已确认",
    "PROCESSING": "处理中",
    "COMPLETED": "已完成",
    "CANCELLED": "已取消",
    "HOLDING": "暂缓",
    "REFUNDED": "已退款",
    "RETURNED": "已退货",
    "FAILED": "已失败",
  }

  const handleSetShopId = shopId => {
    setShopId(shopId);
    onSetShop(shopId);
  }

  return (
    <Card title={t("shopOrders.search.title")} style={{ margin: '20px' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>{t("shopOrders.search.shop")}</div>
          <Select
            value={shopId}
            onChange={handleSetShopId}
            placeholder={t("shopOrders.search.all")}
            style={{ width: '300px' }}
            allowClear
          >
            {
              shops.map(shop => (<Option value={shop.id}>{shop.shopName} - {shop.shopUrl}</Option>))
            }
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>{t("shopOrders.search.orderOrProduct")}</div>
          <Input
            value={orderOrProductName}
            onChange={e => setOrderOrProductName(e.target.value)}
            allowClear
            placeholder={t("shopOrders.search.orderOrProduct")}
            style={{ width: '260px' }}
          />
        </div>

        {/* <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>订单来源</div>
          <Select 
            value={orderSource}
            onChange={setOrderSource}
            placeholder="全部" 
            style={{ width: '300px' }} 
            allowClear
          >
            <Option value="source1">来源1</Option>
            <Option value="source2">来源2</Option>
          </Select>
        </div> */}

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>{t("shopOrders.search.receiver")}</div>
          <Input
            value={recipientName}
            onChange={e => setRecipientName(e.target.value)}
            allowClear
            placeholder={t("shopOrders.search.receiver")}
            style={{ width: '260px' }}
          />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>{t("shopOrders.search.status")}</div>
          <Select
            value={status}
            onChange={setStatus}
            placeholder={t("shopOrders.search.all")}
            style={{ width: '140px' }}
            allowClear
          >
            {
              Object.keys(statuses).map(key => <Option value={key}>{statuses[key]}</Option>)
            }
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>{t("shopOrders.search.timeBetween")}</div>
          <RangePicker
            value={dateRange}
            onChange={setDateRange}
            ranges={ranges}
            style={{ width: '256px' }}
          />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px', marginTop: 24, display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <Button type="primary" style={{ marginRight: 8 }} onClick={handleSearch}>{t("shopOrders.search.search")}</Button>
          <Button style={{ marginRight: 8 }} onClick={resetFilters}>{t("shopOrders.search.clear")}</Button>
        </div>
      </div>
    </Card>
  );
};


export default props => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [currentShop, setCurrentShop] = useState({
    id: 1,
  });
  const [searchParams, setSearchParams] = useState({});
  const [orderType, setOrderType] = useState('SALE_ORDER');

  React.useEffect(() => {
    fetchSaleOrders({ searchParams, pagination })
  }, [pagination.current, pagination.pageSize, searchParams, orderType])

  const fetchSaleOrders = async ({ searchParams, pagination }) => {
    const pageData = await saleOrderApi.getSaleOrders({
      ...searchParams,
      orderType,
      page: pagination.current - 1,
      size: pagination.pageSize,
    })

    setData(pageData.saleOrders || []);
    setPagination({
      ...pagination,
      current: pageData.number + 1,
      total: pageData.recordsTotal,
    })
  }

  const handleConfirmSaleOrder = async orderDetail => {
    const hide = message.loading("正在确认订单", 0);
    const salePoolOrder = await shopApi.confirmSaleOrder(orderDetail.shop.id, orderDetail.id);
    hide();
    message.success("订单已确认，等待处理, 进入订单池[" + salePoolOrder.id + "]");

    fetchSaleOrders({ searchParams, pagination });
  }

  const handlePurchaseSaleOrder = async orderDetail => {
    const hide = message.loading("正在创建采购订单", 0);
    const resp = await purchaseOrderApi.createPurchaseOrderFromSaleOrder(orderDetail.id);
    console.log(resp);
    hide();
    message.success("采购订单已生成");

    fetchSaleOrders({ searchParams, pagination });
  }

  const handleSyncSaleOrders = async () => {
    const hide = message.loading("正在同步订单", 0);
    const resp = await saleOrderApi.syncSaleOrders(currentShop.id);
    hide();
    message.success('订单同步完毕');

    fetchSaleOrders({ searchParams, pagination });
  }

  const handlePageChange = (current, pageSize) => {
    setPagination({
      ...pagination,
      current,
      pageSize,
    })
  };


  const handleCreateManualSaleOrder = async ({ shopId, orderNote, ...params }, products) => {
    if (products.length <= 0) {
      message.error('请先添加商品')
      return;
    }

    const hide = message.loading('正在创建手工订单', 0);
    const resp = await saleOrderApi.createManualSaleOrder(shopId, {
      billingAddress: params,
      deliveryAddress: params,
      orderNote,
      orderProducts: products.map(product => ({ offerId: product.offerId, sku: `${product.skuId}`, quantity: product.quantity })),
    });


    message.success('手工订单创建完成');
    hide();
    setVisible(false);

    fetchSaleOrders({ searchParams, pagination });
  }

  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{}}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={24}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  {t("shopOrders.title")}
                </h3>
                {
                  orderType === 'MANUAL_ORDER' && <Button type='primary' onClick={() => setVisible(true)}>{t("shopOrders.btn.create")}</Button>
                }
                {
                  orderType === 'SALE_ORDER' && <Button type="primary" onClick={handleSyncSaleOrders}>{t("shopOrders.btn.sync")}</Button>
                }
              </div>
            </Col>
            <Col span={24}>
              <nav class="page-layout-nav">
                <div class="nav-item"><a aria-current="page" href="javascript:void(0);" class={`${orderType === 'MANUAL_ORDER' ? 'active' : ''}`} onClick={() => setOrderType('MANUAL_ORDER')}>{t("shopOrders.tab.manual")}</a></div>
                <div class="nav-item"><a href="javascript:void(0);" class={`${orderType === 'SALE_ORDER' ? 'active' : ''}`} onClick={() => setOrderType('SALE_ORDER')}>{t("shopOrders.tab.sale")}</a></div>
              </nav>
            </Col>
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '102px', paddingBottom: '32px' }}>
        <SearchOrders onSync={handleSyncSaleOrders} onSearch={setSearchParams} onReset={() => setSearchParams({})} onSetShop={shopId => setCurrentShop({ id: shopId })}/>
        <div className='order-details-list' style={{ margin: 20 }}>
          {
            data.map(orderDetail => <OrderDetails key={orderDetail.id} orderDetail={orderDetail} onConfirm={handleConfirmSaleOrder} onConfirmPurchase={handlePurchaseSaleOrder} />)
          }
        </div>
        <div className="pagination" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={['5', '10', '20']}
          />
        </div>
        {
          visible && <CreateCustomSaleOrderOverlay onCancel={() => setVisible(false)} onConfirm={handleCreateManualSaleOrder} />
        }

      </div>
    </>
  )
}