import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Checkbox } from 'antd';
import shopApi from '../../servapi/shop';
import { useTranslation } from 'react-i18next';

const ShopListOverlay = ({ visible, onCancel, onConfirm }) => {
  const { t } = useTranslation();
  const [shops, setShops] = useState([]);
  const [selectedShops, setSelectedShops] = useState([]);

  useEffect(() => {
    shopApi.getShopList()
      .then(setShops)
      .catch(console.log);
  }, []);

  // 全选/取消全选处理函数
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedShops(shops.map(shop => shop.id));
    } else {
      setSelectedShops([]);
    }
  };

  // 确认按钮处理函数
  const handleConfirm = () => {
    console.log(selectedShops);
    onConfirm && onConfirm(selectedShops);
  };

  // 检查是否全选
  const isAllSelected = selectedShops.length === shops.length && shops.length > 0;

  return (
    <Modal
      title={t('modal.shopList.title')}
      visible={visible}
      cancelText={t('common.confirm.cancel')}
      okText={t('common.confirm.ok')}
      onOk={handleConfirm}
      onCancel={onCancel}
    >
      <Form layout="vertical" className="hide-required-mark">
        <div style={{ display: 'block' }}>
          <Row style={{ rowGap: '0px' }}>
            <Col span={24}>
              <Form.Item>
                <Checkbox
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                >
                  <span>{t("modal.shopList.all")}</span>
                  <span>(<span className="text-red">{selectedShops.length}</span> Store(s) Selected)</span>
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Checkbox.Group
                  id="shopCodes"
                  style={{ display: 'inline-grid' }}
                  value={selectedShops}
                  onChange={setSelectedShops}
                >
                  {
                    shops.map(shop => (
                      <Checkbox
                        key={shop.id}
                        value={shop.id}
                        className="flex-center-vertical mb-12"
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ height: '28px', width: '28px', display: 'inline-block' }}>
                            <img src="" height="100%" width="100%" alt="" />
                          </div>
                          <span style={{ marginLeft: '8px', fontSize: '16px' }}>{shop.shopName} - {shop.shopUrl}</span>
                        </div>
                      </Checkbox>
                    ))
                  }
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ShopListOverlay;
