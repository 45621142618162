import { create } from "../util/request";
import i18n from 'i18next';
import qs from 'qs';

const api = create({ baseURL: ''});

class PurchaseOrderApi {

  async createPurchaseOrder(params) {
    return await api.post(`/api/v1/purchase_orders`, params);
  }

  async createPurchaseOrderFromSaleOrder(id) {
    return await api.post(`/api/v1/purchase_orders/sale_orders/${id}`);
  }

  async confirmPurchaseOrder(id) {
    return await api.post(`/api/v1/purchase_orders/${id}/confirm`);
  }

  async getPurchaseOrders(params) {
    return await api.get(`/api/v1/purchase_orders?${qs.stringify(params)}`);
  }

  async getPurchaseOrder(id) {
    return await api.get(`/api/v1/purchase_orders/${id}`);
  }

  async getPurchaseOrderProductLogisticsTraces(id) {
    return await api.get(`/api/v1/purchase_order_products/${id}/logistics_traces`)
  }

  async reCreatePurchaseSupplierOrder(psoOrderId) {
    return await api.post(`/api/v1/purchase_supplier_orders/${psoOrderId}/recreate`);
  }
}

const purchaseOrderApi = new PurchaseOrderApi();
export default purchaseOrderApi;