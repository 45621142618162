import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const PushStatus = ({ status }) => {
  const { t } = useTranslation();
  const getStatusText = (status) => {
    switch (status) {
      case 'PENDING':
        return t('status.pushStatus.PENDING');
      case 'SUCCEED':
        return t('status.pushStatus.SUCCEED');
      case 'FAILED':
        return t('status.pushStatus.FAILED');
      default:
        return t('status.pushStatus.UNKNOW');
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};


export default PushStatus;