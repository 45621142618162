import React from 'react';
import { Cascader } from 'antd';
import './ProductsCategory.less';
import axios from 'axios';
// import options from './options.json';
// import langCats from './lang_cats';

import { useTranslation } from 'react-i18next';


// const options = [
//   {
//     value: 'clothing',
//     label: '服装',
//     children: [
//       { value: 'women', label: '女装' },
//       { value: 'men', label: '男装' },
//       { value: 'children', label: '童装' },
//     ],
//   },
//   {
//     value: 'electronics',
//     label: '数码',
//     children: [
//       { value: 'mobile', label: '手机' },
//       { value: 'computer', label: '电脑' },
//       { value: 'photography', label: '摄影' },
//     ],
//   },
//   {
//     value: 'home',
//     label: '家居',
//     children: [
//       { value: 'kitchenware', label: '厨具' },
//       { value: 'furniture', label: '家具' },
//       { value: 'decoration', label: '装饰' },
//     ],
//   },
//   {
//     value: 'sports',
//     label: '运动',
//     children: [
//       { value: 'running', label: '跑步' },
//       { value: 'fitness', label: '健身' },
//       { value: 'outdoor', label: '户外' },
//     ],
//   },
//   {
//     value: 'books',
//     label: '书籍',
//     children: [
//       { value: 'fiction', label: '小说' },
//       { value: 'non-fiction', label: '非小说类' },
//       { value: 'academic', label: '学术' },
//     ],
//   },
//   {
//     value: 'toys',
//     label: '玩具',
//     children: [
//       { value: 'educational', label: '教育玩具' },
//       { value: 'action-figures', label: '动作玩具' },
//       { value: 'board-games', label: '桌游' },
//     ],
//   },
//   {
//     value: 'beauty',
//     label: '美妆',
//     children: [
//       { value: 'skincare', label: '护肤' },
//       { value: 'makeup', label: '化妆品' },
//       { value: 'fragrances', label: '香水' },
//     ],
//   },
//   {
//     value: 'food',
//     label: '食品',
//     children: [
//       { value: 'snacks', label: '零食' },
//       { value: 'beverages', label: '饮料' },
//       { value: 'ingredients', label: '食材' },
//     ],
//   },
//   {
//     value: 'pets',
//     label: '宠物',
//     children: [
//       { value: 'dogs', label: '狗' },
//       { value: 'cats', label: '猫' },
//       { value: 'small-animals', label: '小动物' },
//     ],
//   },
//   {
//     value: 'music',
//     label: '音乐',
//     children: [
//       { value: 'instruments', label: '乐器' },
//       { value: 'albums', label: '专辑' },
//       { value: 'merchandise', label: '周边' },
//     ],
//   },
//   {
//     value: 'health',
//     label: '健康',
//     children: [
//       { value: 'vitamins', label: '维生素' },
//       { value: 'supplements', label: '补充剂' },
//       { value: 'medical-devices', label: '医疗器械' },
//     ],
//   },
//   {
//     value: 'games',
//     label: '游戏',
//     children: [
//       { value: 'video-games', label: '电子游戏' },
//       { value: 'board-games', label: '桌面游戏' },
//       { value: 'puzzles', label: '益智游戏' },
//     ],
//   },
// ];

const langOptions = {
  'en-US':'en-US',
  'es-ES': 'es-ES',
  'ru-RU': 'ru-RU',
  'zh-CN': 'zh-CN',
  'en': 'en-US',
  'es': 'es-ES',
  'ru': 'ru-RU',
  'zh': 'zh-CN',
  'cn': 'zh-CN',
}

export default ({ onChange }) => {
  const { i18n } = useTranslation();
  const getPopupContainer = () => document.getElementById('cascader-container');
  const [opts, setOpts] = React.useState([])

  // const options = langCats[i18n.language];

  React.useEffect(() => {
    axios.get(`/options/${langOptions[i18n.language]}.json`)
      .then(res => {
        setOpts(res.data);
      })
      .catch(console.log);
  }, [i18n.language])

  return (
    <Cascader
      className="ant-cascader-picker ant-cascader-picker-large"
      options={opts}
      placeholder="所有分类"
      getPopupContainer={getPopupContainer}
      popupClassName='products-main-categories-popup'
      changeOnSelect
      expandTrigger='hover'
      onChange={onChange}
      open
    />
  )
}