import { create } from "../util/request";
import i18n from 'i18next';
import qs from 'qs';


const api = create({ baseURL: ''});

class CategoryApi {

  async getAllCategories() {
    const lang = i18n.language; // 获取当前语言
    const langMap = {
      'zh-CN': 'cn',
      'ru-RU': 'ru',
      'en-US': 'en',
      'es-ES': 'es',
    }
    return await api.get(`/categories/${langMap[lang]}.json`);
  }
}

const categoryApi = new CategoryApi();

export default categoryApi;