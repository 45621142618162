import React, { useRef, useState, useEffect } from 'react';
import { Breadcrumb, message } from 'antd';
import { Checkbox, InputNumber, Button, Row, Col, Form, Spin, Table } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import './ProductsSearchSection.less';
import Product from './ProductCard';
import productApi from '../../servapi/product';
// import options from './options.json';
import langCats from './lang_cats';
import { useTranslation } from 'react-i18next';
import { trans } from '../../util/transobj';

const langOptions = {
  'en-US': { currency: 'USD', language: 'en_US', region: 'US' },
  'es-ES': { currency: 'EUR', language: 'es_ES', region: 'ES' },
  'ru-RU': { currency: 'RU', language: 'ru_RU', region: 'RU' },
  'zh-CN': { currency: 'CNY', language: 'cn_ZH', region: 'CN' },
}


const ProductsFilters = ({ keyword = '', onFilterOptionChange, onPriceRangeChange }) => {
  const { t, i18n } = useTranslation();
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [priceRange, setPriceRange] = React.useState([undefined, undefined]);

  React.useEffect(() => {
    if (!keyword) {
      setFilterOptions([]);
      return;
    }

    const option = langOptions[i18n.language];
  
    productApi.getSearchSN({
      "currency": option.currency,
      "keyword": keyword,
      language: option.language,
      "region": option.region
    })
    .then(({ result }) => {
      setFilterOptions(result || []);
    })
    .catch(console.log);
  }, [keyword, i18n.language])

  const [selectedOptions, setSelectedOptions] = React.useState([]);

  // 处理复选框变更
  const handleCheckboxChange = (optionId) => {
    let updatedSelectedOptions;
    if (selectedOptions.includes(optionId)) {
      updatedSelectedOptions = selectedOptions.filter((id) => id !== optionId);
    } else {
      updatedSelectedOptions = [...selectedOptions, optionId];
    }
    setSelectedOptions(updatedSelectedOptions);
    onFilterOptionChange && onFilterOptionChange(updatedSelectedOptions);
  };

  const handlePriceRangeChange = () => {
    if (priceRange[0] != null && priceRange[1] != null && priceRange[0] > priceRange[1]) {
      message.error(t('productSearch.filter.priceValidMsg'));
      return;
    }

    onPriceRangeChange && onPriceRangeChange(priceRange);
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    // 处理表单提交逻辑
  };

  const handleReset = () => {
    // 处理重置表单逻辑
  };

  return (
    <div className="products-main-filters is-expanded">
      <Form className="ant-form ant-form-horizontal flex-grow-1" onSubmit={handleSubmit}>
        {
          filterOptions.map(filter => (
        <Row
          key={filter.id}
          className="products-main-filters-group is-collapsible"
        >
          <Col span={4}>
            <div className="products-main-filters-label">
              <div className="line-height-form-item-control truncate">
                {trans(filter, 'translateName', 'name')}:
              </div>
            </div>
          </Col>
          <Col span={20}>
            <div className="products-main-filters-controls">
              <Row gutter={0}>
                <Col span={24}>
                  {filter.children.map((option) => (
                      <Checkbox
                        id={`option-${option.id}`}
                        checked={selectedOptions.includes(option.id)}
                        onChange={() => handleCheckboxChange(option.id)}
                      >
                        <span
                          style={{
                            fontWeight: selectedOptions.includes(option.id)
                              ? 'bold'
                              : 'normal',
                          }}
                        >
                          {trans(option, 'translateName', 'name')}
                        </span>
                      </Checkbox>
                  ))}
                </Col>

              </Row>
            </div>
          </Col>
        </Row>
          ))
        }
        <Row className="products-main-filters-group">
          <Col span={4}>
            <div className="products-main-filters-label">
              <div className="line-height-form-item-control">{t("productSearch.filter.sorting")}</div>
            </div>
          </Col>
          <Col span={20}>
            <div className="products-main-filters-controls">
              <Row gutter={0}>
                <Col span={24} className="products-main-filters-item flex items-center">
                  <div className="ant-form-item-control-input-content mr-[12px]">
                    <span className="sorter sorter-with-order">{t("productSearch.filter.priceSorting")}</span>
                    <input type="hidden" className="ant-input hidden" value="" />
                  </div>
                  <div className="ant-form-item-control-input-content flex-center-vertical group border border-transparent  hover:border-gray-300 px-[6px] py-[4px]">
                    <InputNumber className="mr-5" placeholder={t("productSearch.filter.lowestPrice")} min={0} value={priceRange[0]} onChange={value => setPriceRange([value, priceRange[1]])} onBlur={handlePriceRangeChange}/>
                    <span className="ml-5 mr-5">-</span>
                    <InputNumber className="ml-5 mr-5" placeholder={t("productSearch.filter.highestPrice")} min={0} value={priceRange[1]} onChange={value => setPriceRange([priceRange[0], value])} onBlur={handlePriceRangeChange}/>
                    <Button className="opacity-0 group-hover:opacity-100" type="primary" onClick={handlePriceRangeChange}>确认</Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* <div className="products-main-filters-form-actions mb-16">
          <Button type="primary" htmlType="submit" className="mr-16">
            搜 索
          </Button>
          <Button type="default" onClick={handleReset}>
            重 置
          </Button>
        </div> */}
      </Form>
      <div className="w-14 text-right ml-auto">
        <a className="products-main-filters-toggle-more">
          <span>{t("productSearch.filter.more")}</span>
          <span className="anticon anticon-up ml-5"><UpOutlined /></span>
        </a>
      </div>
    </div>
  );
}


// const ProductScrollerMoreKeyword = ({ searchParams, selectedFilterOptions, priceRangeOptions }) => {
//   const [loading, setLoading] = useState(false);
//   const { i18n } = useTranslation();
//   const [pageData, setPageData] = useState([]);
//   const [pagination, setPagination] = useState({
//     currentPage: 1,
//     pageSize: 10,
//     totalRecords: 0,
//   });
//   const [selectedRowKeys, setSelectedRowKeys] = useState([]);

//   useEffect(() => {
//     if (!searchParams) return;

//     setLoading(true);
//     const fetchData = async () => {
//       let data = [];
//       let totalRecords = 0;
//       if (searchParams.imageId) {
//         const response = await productApi.searchImage({
//           beginPage: pagination.currentPage,
//           pageSize: pagination.pageSize,
//           country: i18n.language,
//           imageId: searchParams.imageId,
//           ...priceRangeOptions,
//         });
//         data = response.data || [];
//         totalRecords = response.totalRecords || 0;
//       } else {
//         const response = await productApi.search({
//           beginPage: pagination.currentPage,
//           pageSize: pagination.pageSize,
//           country: i18n.language,
//           ...searchParams,
//           ...(selectedFilterOptions ? { snId: selectedFilterOptions } : {}),
//           ...priceRangeOptions,
//         });
//         data = response.data || [];
//         totalRecords = response.totalRecords || 0;
//       }

//       setPageData(data);
//       setPagination((prev) => ({
//         ...prev,
//         totalRecords,
//       }));
//       setLoading(false);
//     };

//     fetchData();
//   }, [pagination.currentPage, searchParams, selectedFilterOptions, priceRangeOptions, i18n.language]);

//   const handleTableChange = (pagination) => {
//     setPagination({
//       ...pagination,
//       currentPage: pagination.current,
//     });
//   };

//   const onSelectChange = (selectedRowKeys) => {
//     setSelectedRowKeys(selectedRowKeys);
//   };

//   // Ant Design Table columns
//   const columns = [
//     {
//       title: 'Product',
//       key: 'product',
//       render: (record) => (
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <img src={record.imageUrl} alt="product" style={{ width: '50px', marginRight: '10px' }} />
//           <div>
//             <div>{record.subject}</div>
//             <div style={{ color: '#888' }}>{(record.sellerIdentities || []).join(', ')}</div>
//           </div>
//         </div>
//       ),
//     },
//     {
//       title: 'Price',
//       dataIndex: ['priceInfo', 'price'],
//       key: 'price',
//       render: (price) => `¥${price}`,
//     },
//     {
//       title: 'Promotion Price',
//       dataIndex: ['priceInfo', 'promotionPrice'],
//       key: 'promotionPrice',
//       render: (promotionPrice) => (promotionPrice ? `¥${promotionPrice}` : 'N/A'),
//     },
//     {
//       title: 'Monthly Sales',
//       dataIndex: 'monthSold',
//       key: 'monthSold',
//     },
//     {
//       title: 'Repurchase Rate',
//       dataIndex: 'repurchaseRate',
//       key: 'repurchaseRate',
//     },
//   ];

//   const rowSelection = {
//     selectedRowKeys,
//     onChange: onSelectChange,
//   };

//   return (
//     <div style={{ paddingTop: 12 }}>
//       <Table
//         columns={columns}
//         dataSource={pageData}
//         rowKey="offerId"
//         rowSelection={rowSelection}
//         pagination={{
//           current: pagination.currentPage,
//           pageSize: pagination.pageSize,
//           total: pagination.totalRecords,
//         }}
//         onChange={handleTableChange}
//         loading={loading}
//       />
//       {loading && (
//         <div style={{
//           height: 42,
//           display: 'flex',
//           justifyContent: 'center',
//           width: '100%',
//         }}>
//           <Spin size="large" />
//         </div>
//       )}
//     </div>
//   );
// };

// export default ProductScrollerMoreKeyword;



const ProductScrollerMoreKeyword = ({ searchParams, selectedFilterOptions, priceRangeOptions }) => {
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const [pageData, setPageData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    totalPage: 1,
    pageSize: 10,
    totalRecords: 0,
  })

  React.useEffect(() => {
    if (!searchParams) {
      return;
    }

    setLoading(true)
    if (searchParams.imageId) {
      productApi.searchImage({
        "beginPage": pagination.currentPage,
        "pageSize": pagination.pageSize,
        "country": i18n.lanugage,
        "imageId": searchParams.imageId,
        ...priceRangeOptions,
      })
      .then(({ data = []}) => {
        if (pagination.currentPage === 1) {
          setPageData([
            ...data,
          ])
        } else {
          setPageData([
            ...pageData,
            ...data,
          ])
        }
        setLoading(false);
      })
    } else {
      productApi.search({
        "beginPage": pagination.currentPage,
        "pageSize": pagination.pageSize,
        "country": i18n.lanugage,
        ...searchParams,
        ...(selectedFilterOptions?{ snId: selectedFilterOptions }: {}),
        ...priceRangeOptions,
      })
        .then(({ data = []}) => {
          if (pagination.currentPage === 1) {
            setPageData([
              ...data,
            ])
          } else {
            setPageData([
              ...pageData,
              ...data,
            ])
          }
          setLoading(false);
        })
    }
  }, [pagination]);

  React.useEffect(() => {
    // setPageData([]);
    setPagination({
      ...pagination,
      currentPage: 1,
    })
  }, [searchParams, selectedFilterOptions, priceRangeOptions, i18n.language])

  const handleCurrentPageChange = currentPage => {
    if (loading) {
      return;
    }
    setPagination({
      ...pagination,
      currentPage
    })
  }

  // useRef用来获取滚动容器的引用
  const scrollContainerRef = useRef(null);

  // 监听滚动事件的处理函数
  const handleScroll = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      // 当滚动到底部时，进行加载下一页的操作
      console.log(scrollContainer.scrollTop + scrollContainer.clientHeight, scrollContainer.scrollHeight)
      if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight - 60) {
        // 增加页面数，并调用传入的onNextPage函数
        const nextPage = pagination.currentPage + 1;
        handleCurrentPageChange(nextPage);
      }
    }
  };

  // useEffect用来在组件挂载时添加滚动事件监听
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      // 添加滚动事件监听
      scrollContainer.addEventListener('scroll', handleScroll);
    }
    
    // 组件卸载时清除事件监听
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]); // 依赖项中只有handleScroll，确保只在handleScroll函数变化时重新注册事件

  return (
    <div className="d-flex flex-wrap mx--5" ref={scrollContainerRef} style={{ overflowY: 'auto', maxHeight: '80vh', paddingTop: 12 }}>
      {(pageData || []).map(product => <Product key={product.id} product={product} />)}
      {loading && (
        <div style={{
          height: 42,
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};



export default ({ searchParams, onReturn }) => {
  const { t, i18n } = useTranslation();
  const [pageData, setPageData] = React.useState({});
  const [selectedFilterOptions, setSelectedFilterOptions] = React.useState('');
  const [priceRangeOptions, setPriceRangeOptions] = React.useState({});
  React.useEffect(() => {
    if (!searchParams) {
      return;
    }

    if (searchParams.imageId) {
      productApi.searchImage({
        "beginPage": 0,
        "country": i18n.lanugage,
        "pageSize": 10,
        "imageId": searchParams.imageId,
        ...(selectedFilterOptions ? { snId: selectedFilterOptions }: {}),
        ...priceRangeOptions,
      })
        .then(setPageData)
    } else {
      productApi.search({
        "beginPage": 0,
        "country": i18n.lanugage,
        "pageSize": 10,
        ...searchParams,
        ...(selectedFilterOptions?{ snId: selectedFilterOptions}: {}),
        ...priceRangeOptions,
      })
        .then(setPageData)
    }
    // console.log(result)
    // setPageData(result);
  }, [searchParams, selectedFilterOptions, priceRangeOptions, i18n.language]);

  function findCategoryPath(data, leafNodeId) {
    // 辅助递归函数，用于查找路径
    function findPath(node, nodeId, currentPath) {
      // 如果当前节点是叶子节点并且 ID 匹配，则返回当前路径
      console.log(node, 'node..')
      if (node.value === nodeId) {
        return currentPath.concat(node.label);
      }

      // 如果当前节点有子节点，则继续递归查找
      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          let path = findPath(child, nodeId, currentPath.concat(node.label));
          if (path) {
            return path;
          }
        }
      }

      // 如果未找到匹配的叶子节点，则返回 null
      return null;
    }

    // 初始调用递归函数
    let path = findPath({ children: data }, leafNodeId, []).slice(1);

    console.log(path, 'patyh..')
    // 如果找到路径，则格式化为指定格式，否则返回空字符串
    if (path) {
      return path.join(' > ');
    } else {
      return '';
    }
  }
  const handleFilterOptionChange = filterOptions => {
    setSelectedFilterOptions(filterOptions.join(','));
  }

  const handlePriceRangeChange = priceRange => {
    let priceRangeOptions = {};

    if (priceRange[0] != undefined && priceRange[0] >= 0) {
      priceRangeOptions.priceStart = `${priceRange[0]}`;
    } else {
      delete priceRangeOptions['priceStart'];
    }

    if (priceRange[1] != undefined && priceRange[1] >= 0) {
      priceRangeOptions.priceEnd = `${priceRange[1]}`;
    } else {
      delete priceRangeOptions['priceEnd'];
    }

    setPriceRangeOptions(priceRangeOptions)
  }
  return (
    <>
      <div className="products-main-breadcrumb mb-24">
        <Breadcrumb>
          <Breadcrumb.Item onClick={onReturn}>
            <span className="cursor-pointer">{t('productSearch.breadcrumb.product')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{t('productSearch.breadcrumb.search')}“{searchParams.imageId ? 'Image' : searchParams.keyword ? searchParams.keyword : findCategoryPath(langCats[i18n.language], searchParams.categoryId)}“</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {/* <ProductScannedImages /> */}
      <ProductsFilters keyword={searchParams?.keyword } onFilterOptionChange={handleFilterOptionChange} onPriceRangeChange={handlePriceRangeChange}/>
      <div className="products-main-search-products">
        {/* <div className="d-flex flex-wrap mx--5">
          {
            (pageData.data || []).map(product => <Product product={product} />)
          }
        </div> */}

        <ProductScrollerMoreKeyword searchParams={searchParams} selectedFilterOptions={selectedFilterOptions} priceRangeOptions={priceRangeOptions}/>
      </div>
    </>
  )
}