import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const SaleOrderStatus = ({ status }) => {
  const getStatusText = (status) => {
    switch (status) {
      case 'NEW':
        return '已创建';
      case 'PENDING_CONFIRM':
        return '待确认';
      case 'CONFIRMED':
        return '已确认';
      case 'PROCESSING':
        return '处理中';
      case 'COMPLETED':
        return '已完成';
      case 'CANCELLED':
        return '已取消';
      case 'ON_HOLD':
        return '暂缓';
      case 'REFUNDED':
        return '已退款';
      case 'RETURNED':
        return '已退货';
      case 'FAILED':
        return '已失败';
      default:
        return '未知状态';
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};


export default SaleOrderStatus;