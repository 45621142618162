import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Button, Tabs, Input, Alert, Form, Row, Col, Select, Spin, Table, Checkbox, Upload, message } from 'antd';
import { SearchOutlined, CloseCircleOutlined, UploadOutlined } from '@ant-design/icons';
import SaleOrderEditForm from './SaleOrderEditForm';
import saleOrderApi from '../../servapi/sale_order';
import axios from 'axios';
const { TabPane } = Tabs;

const ExpandedTable = ({ subItems, onSelectSubItems, selectedRowKeys, checkDisabled = false }) => (
  <Table
    dataSource={subItems}
    pagination={false}
    showHeader={false}
    columns={[
      {
        title: '',
        dataIndex: 'selection',
        key: 'selection',
        render: (_, record) => (
          <Checkbox
            checked={selectedRowKeys.includes(record.skuId) || checkDisabled}
            disabled={checkDisabled}
            onChange={(e) => onSelectSubItems(record.skuId, e.target.checked)}
          />
        ),
      },
      {
        title: '规格',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => (
          <div>
            {record.skuAttributes.map(skuAttribute => (
              <span key={skuAttribute.attributeName} style={{ marginRight: 4 }}>
                {skuAttribute.attributeName}:{skuAttribute.attributeValue}
              </span>
            ))}
          </div>
        )
      },
      {
        title: '数量',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: '价格',
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: '库存',
        dataIndex: 'stock',
        key: 'stock',
      },
    ]}
    rowKey="skuId"
  />
);

const ProductsTable = ({ data, setSelectProductSkus }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const getSelectedSkuIds = (data) => {
    return (data || []).reduce((selectedIds, product) => {
      const selectedSkus = (product.skuInfos || []).filter(skuInfo => skuInfo.selected).map(skuInfo => skuInfo.skuId);
      return [...selectedIds, ...selectedSkus];
    }, []);
  };

  // Initialize selectedRowKeys based on skuInfo.select
  useEffect(() => {
    const initialSelectedSkus = getSelectedSkuIds(data);
    setSelectedRowKeys(initialSelectedSkus);
  }, [data]);

  const onSelectMainItem = (record, isSelected) => {
    let newSelectedRowKeys = [...selectedRowKeys];

    if (isSelected) {
      // Add main item and all subItems to selectedRowKeys
      newSelectedRowKeys = [
        ...newSelectedRowKeys,
        // record.offerId,
        ...record.skuInfos.map(item => item.skuId)
      ];
    } else {
      // Remove main item and all subItems from selectedRowKeys
      newSelectedRowKeys = newSelectedRowKeys.filter(key => key !== record.offerId && !record.skuInfos.map(item => item.skuId).includes(key));
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    const productSkus = (data || []).map(product => getProductSkuArray(product)).reduce((sum, item) => ([...sum, ...item]), []);
    setSelectProductSkus(productSkus.filter(productSku => selectedRowKeys.includes(productSku.skuId)));
  }, [selectedRowKeys]);

  const getProductSkuArray = product => {
    return (product.skuInfos || []).map(skuInfo => ({
      productName: product.productName,
      productImage: product.productImage,
      offerId: product.offerId,
      quoteType: product.quoteType,
      rangePrice: product.rangePrice,
      minOrderQuantity: product.minOrderQuantity,
      ...skuInfo,
      // quantity: 1,
    }))
  }

  const onSelectSubItems = (subItemKey, isSelected) => {
    let newSelectedRowKeys = [...selectedRowKeys];

    if (isSelected) {
      newSelectedRowKeys.push(subItemKey);
    } else {
      newSelectedRowKeys = newSelectedRowKeys.filter(key => key !== subItemKey);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const isMainItemChecked = record => {
    return selectedRowKeys.filter(key => record.skuInfos.map(item => item.skuId).includes(key)).length > 0;
  }

  return (
    <div style={{ marginTop: 24 }}>
      <Table
        dataSource={data}
        columns={[
          {
            title: '',
            dataIndex: 'expand',
            key: 'expand',
            width: 0,
            render: () => <span />,
          },
          {
            title: '',
            dataIndex: 'checkbox',
            key: 'checkbox',
            width: 50,
            render: (_, record) => (
              <Checkbox
                checked={isMainItemChecked(record)}
                onChange={(e) => onSelectMainItem(record, e.target.checked)}
              />
            ),
          },
          {
            title: '商品名称',
            dataIndex: 'name',
            key: 'name',
            width: 320,
            render: (_, record) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 60, height: 60, border: '1px solid #eee', overflow: 'hidden', flexShrink: 0, flexGrow: 0, marginRight: 4 }}>
                  <img src={record.productImage} alt="" style={{ width: '100%', height: '100%' }} />
                </div>
                <div>
                  <span>{record.productName}</span>
                </div>
              </div>
            )
          },
          {
            title: '来源于',
            dataIndex: 'source',
            key: 'source',
            width: 120,
          },
          {
            title: '成本价',
            dataIndex: 'price',
            key: 'price',
            width: 200,
            render: (_, record) => {
              let price = record.price;

              if (record.quoteType === 2) {
                const rangePrices = JSON.parse(record.rangePrices || '[]');

                if (rangePrices.length > 0) {
                  const start = rangePrices[0];
                  const end = rangePrices[rangePrices.length - 1];
                  price = `${start.price} - ${end.price}`;
                }
              }
              return (
                <span>{price}</span>
              )
            }
          },
          {
            title: '库存状态',
            dataIndex: 'stock',
            key: 'stock',
            width: 220,
            render: (stock, record) => (
              <Alert message={record.amountOnSale} type="warning" showIcon={false} />
            ),
          },
        ]}
        pagination={false}
        scroll={{ y: 500 }}
        expandable={{
          expandedRowRender: (record) => (
            <ExpandedTable
              subItems={record.skuInfos}
              onSelectSubItems={onSelectSubItems}
              selectedRowKeys={selectedRowKeys}
            />
          ),
          rowExpandable: (record) => record.skuInfos && record.skuInfos.length > 0,
        }}
        size="small"
        rowKey="offerId"
      />
    </div>
  );
};

const SearchProductTable = ({ onAddSearchProductData }) => {
  const [data, setData] = useState([]);
  const [link, setLink] = useState();

  const handleSearch = async () => {
    if (!link) {
      return;
    }

    const resp = await axios.post(`/api/v1/products_parse/links`, { links: [link] });

    if (resp.status === 200) {
      setData(resp.data.data);
    }
  }


  const handleAddSearchProductData = () => {
    if (data.length <= 0) {
      return;
    }

    onAddSearchProductData(data);
    setData([]);
    setLink('');
  }


  return (
    <>
      <Form layout="vertical">
        <Form.Item>
          <Input.Search
            placeholder="在此处复制并粘贴所需的商品链接"
            onChange={e => setLink(e.target.value)}
            onSearch={handleSearch}
            value={link}
            enterButton={<Button icon={<SearchOutlined />} type="primary" />}
          />
        </Form.Item>
      </Form>
      <div style={{ marginTop: 24 }}>
        <Table
          dataSource={data}
          columns={[
            {
              title: '',
              dataIndex: 'expand',
              key: 'expand',
              width: 0,
              render: () => <span />,
            },
            {
              title: '',
              dataIndex: 'checkbox',
              key: 'checkbox',
              width: 50,
              render: (_, record) => (
                <Checkbox
                  checked
                  disabled
                />
              ),
            },
            {
              title: '商品名称',
              dataIndex: 'name',
              key: 'name',
              width: 320,
              render: (_, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 60, height: 60, border: '1px solid #eee', overflow: 'hidden', flexShrink: 0, flexGrow: 0, marginRight: 4 }}>
                    <img src={record.productImage} alt="" style={{ width: '100%', height: '100%' }} />
                  </div>
                  <div>
                    <span>{record.productName}</span>
                  </div>
                </div>
              )
            },
            {
              title: '来源于',
              dataIndex: 'source',
              key: 'source',
              width: 120,
            },
            {
              title: '成本价',
              dataIndex: 'price',
              key: 'price',
              width: 200,
              render: (_, record) => {
                let price = record.price;

                if (record.quoteType === 2) {
                  const rangePrices = JSON.parse(record.rangePrices || '[]');

                  if (rangePrices.length > 0) {
                    const start = rangePrices[0];
                    const end = rangePrices[rangePrices.length - 1];
                    price = `${start.price} - ${end.price}`;
                  }
                }
                return (
                  <span>{price}</span>
                )
              }
            },
            {
              title: '库存状态',
              dataIndex: 'stock',
              key: 'stock',
              width: 220,
              render: (stock, record) => (
                <Alert message={record.amountOnSale} type="warning" showIcon={false} />
              ),
            },
          ]}
          pagination={false}
          scroll={{ y: 500 }}
          expandable={{
            expandedRowRender: (record) => (
              <ExpandedTable
                subItems={record.skuInfos}
                checkDisabled
              />
            ),
            rowExpandable: (record) => record.skuInfos && record.skuInfos.length > 0,
          }}
          size="small"
          rowKey="offerId"
        />
      </div>
      <Row style={{ background: '#f9f9f9', padding: '16px 24px' }}>
        <Col span={4}></Col>
        <Col span={20} style={{ textAlign: 'right' }}>
          <Button type="primary" style={{ marginLeft: 16 }} onClick={handleAddSearchProductData}>
            加入商品
          </Button>
        </Col>
      </Row>
    </>

  )
}

const SelectProductTable = ({ onNextStep, searchProductData = [] }) => {
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadData, setUploadData] = useState([]);
  const [selectedProductSkus, setSelectProductSkus] = useState([]);

  const uploadProps = {
    name: 'file',
    action: '/api/v1/products_parse/excel',
    beforeUpload: (file) => {
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        message.error('You can only upload Excel files!');
      }
      return isExcel;
    },
    onChange: (info) => {
      if (info.file.status === 'uploading') {
        setIsUploading(true);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setIsUploading(false);
        setUploadData(info.file.response.data);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        setIsUploading(false);
      }

      setFileList([...info.fileList]);
    },
    fileList,
    onRemove: (file) => {
      setFileList((prevList) => prevList.filter((f) => f.uid !== file.uid));
    },
  };

  const handleNextStep = () => {
    onNextStep(selectedProductSkus)
  }

  const data = useMemo(() => {
    return uploadData.concat(searchProductData);
  }, [uploadData, searchProductData])

  return (
    <>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <Input placeholder="输入商品名称进行搜索" suffix={<CloseCircleOutlined />} />
        </Col>
        <Col span={5}>
          <Select placeholder="全部" style={{ width: '100%' }} />
        </Col>
        <Col span={7}>
          <Button type='primary'>搜索</Button>
          <Upload {...uploadProps} multiple={false} accept=".xlsx, .xls">
            <Button icon={<UploadOutlined />} style={{ marginLeft: 8 }}>
              导入Excel
            </Button>
          </Upload>
        </Col>
      </Row>
      <ProductsTable data={data} setSelectProductSkus={setSelectProductSkus} />
      <div style={{ marginTop: 8, display: 'flex', justifyContent: 'flex-end' }}>
        <Button type='primary' onClick={handleNextStep}>下一步</Button>
      </div>
    </>

  )
}


const SelectProductTabs = ({ onNextStep }) => {
  const [searchProductData, setSearchProductData] = useState([]);
  const [activeKey, setActiveKey] = useState('select_product')

  const handleAddSearchProductData = (productData) => {
    setSearchProductData([
      ...searchProductData,
      ...productData,
    ]);

    setActiveKey('select_product');
  }

  return (
    <Tabs defaultActiveKey="saved_product" activeKey={activeKey} onChange={setActiveKey}>
      <TabPane tab="搜索商品" key="search_product" >
        <Spin spinning={false}>
          <SearchProductTable onAddSearchProductData={handleAddSearchProductData}/>
        </Spin>
      </TabPane>
      <TabPane tab="已选择商品" key="select_product">
        <SelectProductTable onNextStep={onNextStep} searchProductData={searchProductData}/>
      </TabPane>
    </Tabs>
  );
};

const CreateCustomSaleOrderOverlay = ({ visible, onCancel, onConfirm }) => {
  const [step, setStep] = useState('select-product');
  const [selectedProductSkus, setSelectProductSkus] = useState([]);

  const handleNextStep = (selectedProductSkus) => {
    console.log(selectedProductSkus, 'selectProducts.......')
    if (selectedProductSkus.length <= 0) {
      message.error('请先添加商品')
      return;
    }
    setSelectProductSkus(selectedProductSkus);
    setStep('confirm-order');
  }

  return (
    <Modal
      title="添加商品"
      visible={true}
      width={1080}
      onCancel={onCancel}
      footer={null}
    >
      <div style={{ display: step === 'select-product' ? 'block' : 'none' }}>
        <SelectProductTabs onNextStep={handleNextStep} />
      </div>
      <div style={{ display: step === 'confirm-order' ? 'block' : 'none' }}>
        <SaleOrderEditForm onPrevStep={() => setStep('select-product')} selectedProductSkus={selectedProductSkus} onConfirm={onConfirm} />
      </div>
    </Modal>
  );
};

export default CreateCustomSaleOrderOverlay;
