import React, { useState, useEffect  } from "react";
import { Layout, Menu, Row, Col, Dropdown, Badge, Avatar, Result, Button, message } from 'antd';
import UserInfo from "./component/UserInfo";
import { Router, Route, Routes, Link, useNavigate, Outlet } from "react-router-dom";
import { inject } from "mobx-react";
import "./Dashboard.less";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import LanguageSwitcher from "../component/LanguageSwitcher";

const { Header, Sider, Content } = Layout;


const langOptions = {
  'en-US': { currency: 'USD', language: 'English'},
  'es-ES': { currency: 'EUR', language: 'Lengua española'},
  'ru-RU': { currency: 'RU', language: 'Русский'},
  'zh-CN': { currency: 'CNY', language: '简体中文'},
  'en': { currency: 'USD', language: 'English'},
  'es': { currency: 'EUR', language: 'Lengua española'},
  'ru': { currency: 'RU', language: 'Русский'},
  'zh': { currency: 'CNY', language: '简体中文'},
}


export default inject('userStore')(({ children, userStore }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [openKeys, setOpenKeys] = useState([]);
  const langOption = langOptions[i18n.language];

  useEffect(() => {
    // Set initial open keys based on the current route
    const pathArray = location.pathname.split('/').filter(Boolean);
    if (pathArray.length > 1) {
      setOpenKeys([pathArray[0]]);
    }
  }, [location]);


  React.useEffect(() => {
    const load = async () => {
      try {
        const userInfo = await userStore.loadUserInfo();

        console.log(userInfo.active, userInfo.username, 'ac.....')
        if (userInfo.active === false) {
          navigate({
            pathname: '/activate',
          });
        }
      } catch (e) {
        navigate({
          pathname: '/login',
        });
      }
    }

    load();
  }, []);

    // Handle menu item click to open corresponding SubMenu
    const handleMenuClick = ({ keyPath }) => {
      if (keyPath.length > 1) {
        setOpenKeys([keyPath[1]]);
      } else {
        setOpenKeys([]);
      }
    };

    console.log(openKeys, '...')

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="ant-layout-header layout-header">
        <Row style={{ rowGap: '0px' }}>
          <Col span={16} className="ant-col ant-col-16 flex">
            {/* <a href="/cn/"><img className="logo" src="https://cdn.buckydrop.com/starit-buckydrop/2024/dist/logo.1829684c.png" alt="BuckyDrop" lang="cn" width="34" height="36" /></a> */}
            <div className="tab-group">
              <Link to="/">
                <img src="/images/logo.png" style={{ height: '32px', marginTop: '16px' }} />
                {/* <span className="text-white ml-8 leading-12 inline-block">
                </span> */}
              </Link>
            </div>
            {/* <div className="inline-block text-opacity-60 ml-6">
              <Dropdown overlay={menu} trigger={['click']} style={{ width: '376px' }}>
                <div className="ant-dropdown-trigger px-4 h-8 leading-8 inline-block bg-gray-200 mr-2 rounded-md">
                  商品推送状态：暂无数据 <DownOutlined className="ml-5" />
                </div>
              </Dropdown>
              <ReloadOutlined className="anticon anticon-reload" style={{ cursor: 'pointer', padding: '15px 0px', color: 'rgb(255, 255, 255)' }} />
            </div> */}
          </Col>
          <Col span={8} style={{ height: '64px', textAlign: 'right' }}>
            <div className="ant-space ant-space-horizontal ant-space-align-center">
              <div className="ant-space-item flex items-center cursor-pointer relative language-action" style={{ height: '64px' }}>
                {/* <a href="" target="_blank" className="help-link">
                  <QuestionCircleOutlined className="anticon anticon-question-circle" style={{ fontSize: '18px' }} />
                </a> */}
                <span className="h-[25px] overflow-wrap-break-word text-white text-[16px] font-AlibabaSans-Medium font-500 text-center whitespace-nowrap leading-[25px] ml-[8px]">{langOption.language}</span>
                <span className="w-[3px] h-[25px] overflow-wrap-break-word text-white text-[16px] font-AlibabaSans-Light font-300 text-center whitespace-nowrap leading-[25px] ml-[8px]">|</span>
                <span className="h-[25px] overflow-wrap-break-word text-white text-[16px] font-AlibabaSans-Medium font-500 text-center whitespace-nowrap leading-[25px] ml-[8px]">{langOption.currency}</span>
                <img
                  className="w-[12px] h-[6px] mx-[6px]"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng86886defa61a1e7a7d02e875dca0ff0f116935ee83420e3301bdf8692166c5e9"
                />
                <LanguageSwitcher />
              </div>
              <div className="ant-space-item">
                <UserInfo />
              </div>
            </div>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider width={240} className="site-layout-background side-default" style={{ height: 'calc(100vh - 64px)', zIndex: 100 }}>
          <Menu
            mode="inline"
            selectedKeys={[location.pathname.substring(1)]}
            // openKeys={openKeys}
            onClick={handleMenuClick}
            // onOpenChange={(keys) => setOpenKeys(keys)}
            style={{ height: '100%', borderRight: 0, overflowY: 'scroll' }}
          >
            <Menu.Item key="dashboard">
              <Link to="/dashboard">
                {t('dashboard.menu.home')}
              </Link>
            </Menu.Item>
            <Menu.SubMenu title={t('dashboard.menu.sourcing.productSourcing')} key="product">
              <Menu.Item key="product/select_products">
                <Link to="/product/select_products">
                {t('dashboard.menu.sourcing.productSelection')}
                </Link>
              </Menu.Item>
              <Menu.Item key="product/select_collection">
                <Link to="/product/select_collection">
                {t('dashboard.menu.sourcing.productCollection')}
                </Link>
              </Menu.Item>
              <Menu.Item key="product/listing_products">
                <Link to="/product/listing_products">
                {t('dashboard.menu.sourcing.productList')}
                </Link>
              </Menu.Item>
              <Menu.Item key="product/shop_products">
                <Link to="/product/shop_products">
                {t('dashboard.menu.sourcing.shopProducts')}
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title={t('dashboard.menu.orders.orders')}key="order">
              <Menu.Item key="order/sale_orders">
                <Link to="/order/sale_orders">
                {t('dashboard.menu.orders.saleOrders')}
                </Link>
              </Menu.Item>
              <Menu.Item key="order/order_fullfill">
                <Link to="/order/order_fullfill">
                {t('dashboard.menu.orders.orderFulfillments')}
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="order/delivery_fullfill">
                <Link to="/order/order_delivery">
                  订单发货
                </Link>
              </Menu.Item> */}
            </Menu.SubMenu>
            <Menu.SubMenu title={t('dashboard.menu.purchase.purchase')} key="purchase">
              <Menu.Item key="purchase/purchase_orders">
                <Link to="/purchase/purchase_orders">
                  {t('dashboard.menu.purchase.purchaseOrders')}
                </Link>
              </Menu.Item>
              <Menu.Item key="purchase/purchase_products">
                <Link to="/purchase/purchase_products">
                  {t('dashboard.menu.purchase.purchaseProducts')}
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title={t('dashboard.menu.shop.shop')} key="shop">
              <Menu.Item key="shop/shops">
                <Link to="/shop/shops">
                  {t('dashboard.menu.shop.mineShop')}
                </Link>
              </Menu.Item>
              <Menu.Item key="shop/manage_shop">
                <Link to="/shop/manage_shop">
                  {t('dashboard.menu.shop.manageShop')}
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title={t('dashboard.menu.tools.tools')} key="tool">
              <Menu.Item key="tool/toolbox">
                <Link to="/tool/toolbox">
                  {t('dashboard.menu.tools.toolBox')}
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title= {t('dashboard.menu.user.user')} key="user">
              <Menu.Item key="user/profile">
                <Link to="/user/profile">
                {t('dashboard.menu.user.profile')}
                </Link>
              </Menu.Item>
              <Menu.Item key="user/feedback">
                <Link to="/user/feedback">
                {t('dashboard.menu.user.feedback')}
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title={t('dashboard.menu.setting.setting')} key="setting">
              <Menu.Item key="setting/order_setting">
                <Link to="/setting/order_setting">
                {t('dashboard.menu.setting.orderSetting')}
                </Link>
              </Menu.Item>
              <Menu.Item key="setting/shipping_setting">
                <Link to="/setting/shipping_setting">
                {t('dashboard.menu.setting.shipSetting')}
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Sider>
        <Layout style={{}}>
          <Content
            className="site-layout-background"
            style={{
              padding: 0,
              margin: 0,
              minHeight: 280,
              position: 'relative',
              paddingLeft: 0,
              paddingRight: 0,
              overflow: 'hidden',
              background: '#f5f5f5'
            }}
          >
            <Outlet />
            {/* <ProductsIndex /> */}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
})


// "选品寻货" (Product Sourcing)
// "选品" (Product Selection)
// "商品列表" (Product List)
// "店铺商品" (Shop Products)
// "订单" (Orders)
// "订单管理" (Order Management)
// "备货" (Stock)
// "备货管理" (Stock Management)
// "服务" (Services)
// "我的服务" (My Services)
// "服务市场" (Service Marketplace)
// "店铺" (Shop)
// "店铺管理" (Shop Management)
// "工具" (Tools)
// "工具箱" (Toolbox)
// "B2B服务" (B2B Services)