

export function getCurrencySymbol(currencyCode) {
  const currencySymbols = {
      'USD': '$',  // 美元
      'EUR': '€',  // 欧元
      'GBP': '£',  // 英镑
      'JPY': '¥',  // 日元
      'AUD': 'A$', // 澳元
      'CAD': 'C$', // 加元
      'CHF': 'CHF',// 瑞士法郎
      'INR': '₹',  // 印度卢比
      'ZAR': 'R',   // 南非币
      'RUB': '₽',   // 卢布
      'CNY': '¥', //人民币
      // 添加其他币种
  };

  return currencySymbols[currencyCode] || currencyCode; // 如果未找到，返回币种代码
}