import React, { useState, useEffect } from 'react';
import { Card, Typography, Tag, Button, Form, Input, Select, Row, Col, Table, InputNumber, Image } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import shopApi from '../../servapi/shop';

const { Text, Link } = Typography;
const { Option } = Select;

const dataSource = [
  {
    key: "1",
    image:
      "https://cbu01.alicdn.com/img/ibank/O1CN01bMVvI01S2bezQDpM0_!!2210898012189-0-cib.jpg",
    title:
      "德国进口RitterSport瑞特斯波德巧克力运动排块牛奶夹心黑巧克力",
    link: "https://detail.1688.com/offer/642154137407.html",
    description: "全榛子白巧克力100g",
    quantity: 1,
    price: 11.5,
  },
  {
    key: "2",
    image:
      "https://cbu01.alicdn.com/img/ibank/O1CN01VMO18f1S2bmQUBphB_!!2210898012189-0-cib.jpg",
    title:
      "德国进口RitterSport瑞特斯波德巧克力运动排块牛奶夹心黑巧克力",
    link: "https://detail.1688.com/offer/642154137407.html",
    description: "可可威化饼干夹心牛奶巧克力100g",
    quantity: 1,
    price: 10.5,
  },
  {
    key: "3",
    image:
      "https://cbu01.alicdn.com/img/ibank/O1CN01SZaHYl28VCQleAOxP_!!2647167937.jpg",
    title: "纯棉短袖t恤女2024年新款宽松韩系时髦小众减龄显瘦上衣夏装ins潮",
    link: "https://detail.1688.com/offer/772066725288.html",
    description: "杏色（SO熊） / 4XL",
    quantity: 1,
    price: 10.5,
  },
];



const SaleOrderEditForm = ({ selectedProductSkus, onPrevStep, onConfirm }) => {
  const [form] = Form.useForm(); // 使用 Form.useForm 创建 form 实例
  const [currentShop, setCurrentShop] = useState({});
  const [shops, setShops] = useState([]);
  const [products, setProducts] = useState(selectedProductSkus);

  useEffect(() => {
    setProducts(selectedProductSkus)
  }, [selectedProductSkus])

  useEffect(() => {
    shopApi.getShopList()
      .then(shops => {
        setShops(shops);
        setCurrentShop(shops[0]);
        form.setFieldsValue({ shopId: shops[0]?.id }); // 设置表单初始值
      })
      .catch(console.log);
  }, []);

  const handleShopChange = id => {
    const shop = shops.filter(shop => shop.id === id)[0];
    setCurrentShop({ ...shop });
  };

  const initialValues = {
    orderNote: '测试订单',
    firstName: 'pinta',
    telephone: '13957781826',
    postCode: '310000',
    country: '中国',
    state: '浙江',
    city: '杭州',
    company: '平塔',
    streetAddress: '永中街道办事处',
  };

  const handleConfirm = () => {
    // 获取表单的所有值
    form.validateFields().then(values => {
      console.log('Form Values:', values);
      onConfirm(values, products); // 将表单数据传递到 onConfirm 回调函数
    }).catch(error => {
      console.log('Validation Failed:', error);
    });
  }

  // 处理数量变更
  const handleQuantityChange = (quantity, record) => {
    // 根据产品的 key 更新相应产品的数量
    const updatedProducts = products.map(product =>
      product.skuId === record.skuId ? { ...product, quantity } : product
    );
    setProducts(updatedProducts); // 更新状态
  };

  const handleRemoveItem = (record) => {
    const updatedProducts = products.filter(product => product.skuId !== record.skuId);
    setProducts(updatedProducts); // 更新产品列表
  };

  const columns = [
    {
      title: "商品",
      dataIndex: "image",
      key: "image",
      render: (image, record) => (
        <div style={{ display: "flex", alignItems: "start" }}>
          <Image
            width={60}
            height={60}
            src={record.productImage}
            alt={record.productName}
            style={{ marginRight: 16 }}
          />
          <div>
            <Text ellipsis={{ rows: 2, expandable: false }}>
              <Link href={record.link} target="_blank">
                {record.productName}
              </Link>
            </Text>
          </div>
        </div>
      ),
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <div>
          {record.skuAttributes.map(skuAttribute => (
            <span key={skuAttribute.attributeName} style={{ marginRight: 4 }}>
              {skuAttribute.attributeName}:{skuAttribute.attributeValue}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: "数量",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity, record) => (
        <div>
          <InputNumber min={record.minOrderQuantity || 1} value={quantity} onChange={(value) => handleQuantityChange(value, record)} />
          <span> × {record.price}</span>
        </div>
      ),
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => <a onClick={() => handleRemoveItem(record)}>移除</a>,
    },
  ];
  

  return (
    <>
      <Form
        layout="vertical"
        form={form} // 绑定 form 实例
        initialValues={initialValues} // 设置初始值
        style={{ padding: 16, background: '#fafafa', borderTop: '1px solid #e8e8e8' }}
      >
        <Row gutter={16}>
          {/* First Col: 店铺 */}
          <Col span={8}>
            <Form.Item label="店铺" name="shopId" rules={[{ required: true }]}>
              <Select placeholder="选择店铺" onChange={handleShopChange}>
                {shops.map(shop => (
                  <Option key={shop.id} value={shop.id}>
                    {shop.shopName} - {shop.shopUrl}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* Second Col: 订单备注 */}
          <Col span={16}>
            <Form.Item label="订单备注" name="orderNote">
              <Input placeholder="请输入订单备注" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          {/* 姓名 */}
          <Col span={8}>
            <Form.Item label="姓名" name="firstName">
              <Input placeholder="姓名" />
            </Form.Item>
          </Col>
          {/* 电话 */}
          <Col span={8}>
            <Form.Item label="电话" name="telephone">
              <Input placeholder="电话" />
            </Form.Item>
          </Col>
          {/* 邮编 */}
          <Col span={8}>
            <Form.Item label="邮编" name="postCode">
              <Input placeholder="邮编" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {/* 国家/地区 */}
          <Col span={8}>
            <Form.Item label="国家/地区" name="country" rules={[{ required: true }]}>
              <Select placeholder="选择国家">
                <Option value="中国">中国</Option>
                <Option value="美国">美国</Option>
              </Select>
            </Form.Item>
          </Col>
          {/* 省/州 */}
          <Col span={8}>
            <Form.Item label="省/州" name="state">
              <Select placeholder="选择州/省">
                <Option value="浙江">浙江</Option>
                <Option value="江苏">江苏</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="城市" name="city">
              <Select placeholder="选择城市">
                <Option value="杭州">杭州</Option>
                <Option value="宁波">宁波</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {/* 公司 */}
          <Col span={8}>
            <Form.Item label="公司" name="company">
              <Input placeholder="公司" />
            </Form.Item>
          </Col>
          {/* 街道地址 */}
          <Col span={8}>
            <Form.Item label="街道地址" name="streetAddress">
              <Input placeholder="街道地址" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* Table Section */}
      <Table
        dataSource={products}
        columns={columns}
        pagination={false}
        tableLayout="auto"
        rowKey={"skuId"}
      />
      <div style={{ marginTop: 8, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onPrevStep} style={{ marginRight: 8 }}>上一步</Button>
        <Button type='primary' onClick={handleConfirm}>确认创建</Button>
      </div>
    </>
  );
};


export default SaleOrderEditForm;
