import React, { useState, useEffect } from 'react';
import { Typography, Card, Button, Descriptions, Divider, DatePicker, Table, Tag, Pagination, Row, Col, Select, Input, Popover, message } from 'antd';
import Dashboard from './Dashboard';
import './PurchaseOrderList.less';
import saleOrderApi from '../servapi/sale_order';
import shopApi from '../servapi/shop';
import { FileTextOutlined, ClockCircleOutlined, UserOutlined, FormOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import purchaseOrderApi from '../servapi/purchase_order';
import PurchaseOrderStatus from './component/status/PurchaseOrderStatus';
import OrderPayStatus from './component/status/OrderPayStatus';
import SupplierOrderStatus from './component/status/SupplierOrderStatus';
import { useTranslation } from 'react-i18next';
import SupplierCrossOrderProductLogisticsStatus from './component/status/SupplierCrossOrderProductLogisticsStatus';
import LogisticsTrackingPopup from './component/LogisticsTraceOverlay';
import { formatDateTime } from '../util/date';

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;



const SearchOrders = () => {
  return (
    <Card title="搜索订单" style={{ margin: '20px' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>订单号或商品名称</div>
          <Input placeholder="订单号或商品名称" style={{ width: '260px' }} />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>订单来源</div>
          <Select placeholder="全部" style={{ width: '300px' }} allowClear>
            <Option value="source1">来源1</Option>
            <Option value="source2">来源2</Option>
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>店铺</div>
          <Select placeholder="全部" style={{ width: '300px' }} allowClear>
            <Option value="shop1">店铺1</Option>
            <Option value="shop2">店铺2</Option>
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>收件人姓名</div>
          <Input placeholder="输入收件人姓名" style={{ width: '260px' }} />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>状态</div>
          <Select placeholder="全部" style={{ width: '140px' }} allowClear>
            <Option value="status1">状态1</Option>
            <Option value="status2">状态2</Option>
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>国家/地区</div>
          <Select placeholder="全部" style={{ width: '140px' }} allowClear>
            <Option value="country1">国家1</Option>
            <Option value="country2">国家2</Option>
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>时间区间</div>
          <RangePicker style={{ width: '256px' }} />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px', display: 'flex', alignItems: 'flex-end' }}>
          <Button type="primary" style={{ marginRight: 8 }}>搜索</Button>
          <Button>清除</Button>
        </div>
      </div>
    </Card>
  );
};



const SupplierOrderCard = ({ supplierOrder = {}, onReCreateSupplierOrder }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  // Calculate the initial remaining time
  const calculateRemainingTime = () => {
    const createdTime = new Date(supplierOrder.createdTime).getTime(); // Convert createdTime to timestamp
    const now = new Date().getTime(); // Current timestamp
    const elapsedTime = (now - createdTime) / 1000; // Elapsed time in seconds
    const remainingTime = supplierOrder.payTimeout - elapsedTime; // Remaining time in seconds
    return Math.max(remainingTime, 0); // Ensure it's not negative
  };

  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  // Countdown logic
  useEffect(() => {
    if (remainingTime > 0) {
      const intervalId = setInterval(() => {
        setRemainingTime((time) => time - 1);
      }, 1000); // Decrease by 1 every second

      return () => clearInterval(intervalId); // Clear interval on component unmount
    }
  }, [remainingTime]);


  useEffect(() => {
    setRemainingTime(calculateRemainingTime())
  }, [supplierOrder.payTimeout])

  const formatTime = (time) => {
    const days = Math.floor(time / (3600 * 24));
    const hours = Math.floor((time % (3600 * 24)) / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    return `${days}天${hours}小时${minutes}分`;
  };

  const getOrderProductLogisticsStatus = record => {
    const { crossOrders = [] } = supplierOrder;
    const crossOrderProducts = crossOrders.map(crossOrder => crossOrder.products).reduce((sum, products) => [...sum, ...products], []);

    const filtered = crossOrderProducts.filter(crossOrderProduct => `${crossOrderProduct.skuId}` === record.sku);

    if (filtered.length > 0) {
      return filtered[0].logisticsStatus;
    }
  }

  const columns = [
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={record.productImage} alt={record.productName} style={{ height: 56, width: 56, border: '1px solid #eee', marginRight: 8 }} />
          <span>{record.productName}</span>
        </div>
      ),
      width: '30%'
    },
    {
      title: 'Sku',
      dataIndex: 'skuInfo',
      key: 'skuInfo',
      width: '15%'
    },
    {
      title: 'Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: '10%',
      render: (_, record) => (<span>{t("common.currency.symbol")}{record.unitPrice}</span>)
    },
    {
      title: 'Quantity',
      key: 'quantity',
      render: (text, record) => (
        <>
          <div><strong>{record.quantity}</strong></div>
        </>
      ),
      width: '10%'
    },
    {
      title: 'Logistics Status',
      key: 'logistics',
      render: (text, record) => (
        <>
          <div><Tag><SupplierCrossOrderProductLogisticsStatus status={getOrderProductLogisticsStatus(record)} /></Tag></div>
        </>
      ),
      width: '15%'
    },
    {
      title: 'Detail',
      key: 'detail',
      render: (text, record) => (
        <>
          <div style={{ color: '#999', cursor: 'pointer' }}>
            <LogisticsTrackingPopup id={record.id} idType="psoProductId" />
          </div>
        </>
      ),
      width: '20%'
    },
  ];

  const gotoPay = supplierOrder => {
    window.open(supplierOrder.payUrl);
  }

  const reCreatePurchaseSupplierOrder = async (supplierOrder) => {
    onReCreateSupplierOrder(supplierOrder);
  }

  const dataSource = showAll ? supplierOrder.orderProducts : supplierOrder.orderProducts.slice(0, 3);

  return (
    <Card bodyStyle={{ padding: 0 }} style={{ borderRadius: 0 }} className="OVWKvl3A">
      <Card bodyStyle={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }} style={{ border: 0 }}>
        <Card.Meta
          style={{ paddingTop: 0, paddingBottom: 16 }}
          title={
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: 14, paddingLeft: 8 }}>
                <span>供应商采购单：</span>
                <span style={{ marginLeft: 8 }}>{supplierOrder.orderId}</span>
              </div>
              {/* <div style={{ display: 'flex', alignItems: 'center', fontSize: 14, paddingLeft: 8 }}>
                <span>供应商：</span>
                <span style={{ marginLeft: 8 }}>{supplierOrder.supplierName ? supplierOrder.supplierName : supplierOrder.supplierSellerNo}</span>
              </div> */}
              {
                supplierOrder.status === 'PENDING_PAY' && supplierOrder.payUrl &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button type='primary' onClick={() => gotoPay(supplierOrder)} style={{ marginLeft: 8 }}>支付订单</Button>
                  {remainingTime > 0 && (
                    <div style={{ marginLeft: 8, fontSize: 14 }}>
                      剩余时间：{formatTime(remainingTime)}
                    </div>
                  )}
                </div>
              }
              {
                supplierOrder.status === 'CANCELLED' &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button type='primary' onClick={() => reCreatePurchaseSupplierOrder(supplierOrder)} style={{ marginLeft: 8 }}>重新创建</Button>
                </div>
              }
            </div>
          }
          description={
            <Descriptions size="small" column={3} style={{ paddingLeft: 8 }}>
              {/* <Descriptions.Item>1688订单：<a href="/cn/admin/orders/po/P3048557127001">P3048557127001</a></Descriptions.Item> */}
              <Descriptions.Item>供应商：
                <a href={`https://s.1688.com/company/company_search.htm?keywords=${encodeURIComponent(supplierOrder.supplier.companyName)}`} target='_blank'>
                  <span style={{ marginLeft: 8 }}>{supplierOrder.supplier.companyName ? supplierOrder.supplier.companyName : supplierOrder.supplier.supplierNo}</span>
                </a>
              </Descriptions.Item>
              <Descriptions.Item>订单状态：<Tag><SupplierOrderStatus status={supplierOrder.status} /></Tag></Descriptions.Item>
              <Descriptions.Item>支付状态：<Tag><OrderPayStatus status={supplierOrder.payStatus} /></Tag></Descriptions.Item>
            </Descriptions>
          }
        />
        <Row gutter={0} style={{ borderTop: '1px solid #dcdcdc', }}>
          <Col span={20}>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              size="small"
              style={{ borderRadius: 0 }}
            />
            {
              supplierOrder.orderProducts.length > 3 && (
                <div style={{ textAlign: 'center', }}>
                  <Button type="link" onClick={() => setShowAll(!showAll)}>
                    {showAll ? <UpOutlined /> : <DownOutlined />}
                    {showAll ? '隐藏' : `还有${supplierOrder.orderProducts.length - 3}个商品，查看所有`}
                  </Button>
                </div>
              )
            }
          </Col>
          <Col span={4}>
            <Descriptions size="small" column={1} style={{ borderLeft: '1px solid #dcdcdc', height: '100%', paddingTop: 8, paddingLeft: 8 }}>
              <Descriptions.Item label="商品件数">
                <Text strong><span>{supplierOrder.orderProducts.length}</span></Text>
              </Descriptions.Item>
              <Descriptions.Item label="运费">
                <Text strong><span>{t("common.currency.symbol")}{supplierOrder.shippingFee}</span></Text>
              </Descriptions.Item>
              <Descriptions.Item label="总价">
                <Text strong>
                  <span>{t("common.currency.symbol")}{supplierOrder.orderTotal}</span>
                </Text>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>
    </Card>
  );
};

const PurchaseOrderCard = ({ purchaseOrder, onConfirm, onReCreateSupplierOrder }) => {
  const { t } = useTranslation();
  const [showSupplierOrders, setShowSupplierOrders] = useState(false);

  const toggleSupplierOrders = () => {
    setShowSupplierOrders(prevState => !prevState);
  };

  const gotoPay = (purchaseOrder) => {
    const payUrls = purchaseOrder.purchaseSupplierOrders.map(order => order.payUrl);

    const orderIds = payUrls.flatMap(url => {
      const match = url.match(/orderId=([\d,]+)/);
      if (match) {
        return match[1].split(',');
      }
      return [];
    });

    return window.open(`https://trade.1688.com/order/cashier.htm?orderId=${orderIds.join(',')}`);
  };

  const text = '11';

  const handleConfirm = (e, purchaseOrder) => {
    e.stopPropagation();
    onConfirm(purchaseOrder)
  }

  const saleOrderIds = purchaseOrder.purchaseSupplierOrders
    .map(purchaseSupplierOrder => purchaseSupplierOrder.saleOrderIds) // 提取每个 saleOrderIds 数组
    .reduce((sum, item) => sum.concat(item), []); // 合并数组

  // 如果需要去重
  const uniqueSaleOrderIds = [...new Set(saleOrderIds)];

  const popoverContent = (
    <div>
      {uniqueSaleOrderIds.map(id => (
        <a key={id}>{id}</a>
      ))}
    </div>
  );
  return (
    <Card bodyStyle={{ padding: 0, background: 'transparent' }} style={{ marginBottom: 16, borderRadius: 0 }}>
      <table className='iIzGMbY1' style={{ marginBottom: 8, paddingTop: 8, cursor: 'pointer' }} onClick={toggleSupplierOrders}>
        <tbody>
          <tr>
            <td>
              <Descriptions style={{ paddingLeft: 16, paddingTop: 8 }}>
                <Descriptions.Item>
                  <FileTextOutlined /> <span className="ml-8">采购订单：</span>
                  <a target="_blank" >{purchaseOrder.orderId}</a>
                </Descriptions.Item>
              </Descriptions>
            </td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <FileTextOutlined /> <span className="ml-8">销售订单：</span>
                  {
                    uniqueSaleOrderIds.length > 1 && (
                      <Popover content={popoverContent} title="销售订单">
                        <Tag>订单列表</Tag>
                      </Popover>
                    )
                  }
                  {
                    uniqueSaleOrderIds.length == 1 && (
                      <a target="_blank" >{uniqueSaleOrderIds[0]}</a>
                    )
                  }
                </Descriptions.Item>
              </Descriptions>
            </td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <ClockCircleOutlined /> <span className="ml-8">创建时间：</span>
                  <span>{formatDateTime(purchaseOrder.createdTime)}</span>
                </Descriptions.Item>
              </Descriptions>
            </td>
          </tr>
          <tr>
            <td>
              <Descriptions style={{ paddingLeft: 16, paddingTop: 8 }}>
                <Descriptions.Item>
                  <UserOutlined /> <span className="ml-8">收货信息：</span>
                  <span>Nicolas, Gotland, 杭州市滨江区, Sweden, 浙新小区</span>
                </Descriptions.Item>
              </Descriptions>
            </td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <UserOutlined /> <span className="ml-8">采购状态：</span>
                  <span><PurchaseOrderStatus status={purchaseOrder.status} /></span>
                </Descriptions.Item>
              </Descriptions>
            </td>
            <td>
              <div>
                <span>
                  {purchaseOrder.status === 'NEW' && <Button type='primary' onClick={e => handleConfirm(e, purchaseOrder)}>确认采购</Button>}
                </span>
                {purchaseOrder.status !== 'NEW' && (
                  <Descriptions style={{ paddingTop: 8 }}>
                    <Descriptions.Item>
                      <UserOutlined /> <span className="ml-8">已支付订单数：</span>
                      <span>{purchaseOrder.purchaseSupplierOrders.filter(purchaseSupplierOrder => purchaseSupplierOrder.payStatus === 'PAID').length}/{purchaseOrder.purchaseSupplierOrders.length}</span>
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        <Button type="link" onClick={toggleSupplierOrders}>
          {showSupplierOrders ? <UpOutlined /> : <DownOutlined />}订单详情
        </Button>
      </div>
      {showSupplierOrders && (purchaseOrder.purchaseSupplierOrders || []).map(supplierOrder => (
        <SupplierOrderCard key={supplierOrder.id} supplierOrder={supplierOrder} onReCreateSupplierOrder={onReCreateSupplierOrder}/>
      ))}
      {
        showSupplierOrders && (
          <div style={{ paddingRight: 24 }}>
            <Descriptions size="small" column={1} style={{ height: '100%', paddingTop: 8, paddingLeft: 8, }}>
              <Descriptions.Item label="总商品件数" style={{ float: 'right' }}>
                <Text strong style={{ color: 'blue' }}>
                  <span>
                    {purchaseOrder.purchaseSupplierOrders.map(purchaseSupplierOrder => purchaseSupplierOrder.orderProducts.length).reduce((sum, len) => sum += len, 0)}
                  </span>
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="总运费" style={{ float: 'right' }}>
                <Text strong style={{ color: 'blue' }}><span>{t("common.currency.symbol")}{purchaseOrder.shippingFee}</span></Text>
              </Descriptions.Item>
              <Descriptions.Item label="共计价格" style={{ float: 'right' }}>
                <Text strong style={{ color: 'blue' }}>
                  <span>{t("common.currency.symbol")}{purchaseOrder.orderTotal}</span>
                </Text>
              </Descriptions.Item>
            </Descriptions>
          </div>
        )
      }
    </Card>
  );
};

export default props => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100,
  });

  React.useEffect(() => {
    fetchPurchaseOrders({ pagination });
  }, [pagination.current, pagination.pageSize])

  const fetchPurchaseOrders = async ({ pagination }) => {
    const pageData = await purchaseOrderApi.getPurchaseOrders({
      page: pagination.current - 1,
      size: pagination.pageSize,
    })

    setData(pageData.purchaseOrders || []);
    setPagination({
      ...pagination,
      current: pageData.number + 1,
      total: pageData.recordsTotal,
    })
  }

  const handleConfirmPurchaseOrder = async orderDetail => {
    const hide = message.loading("正在确认采购单", 0);
    const purchaseOrder = await purchaseOrderApi.confirmPurchaseOrder(orderDetail.id);
    hide();
    message.success("采购单已确认，等待采购处理");

    fetchPurchaseOrders({
      pagination
    })
  }

  const handlePageChange = (current, pageSize) => {
    setPagination({
      ...pagination,
      current,
      pageSize,
    })
  };

  const handleReCreatePurchaseSupplierOrder = async supplierOrder => {
    const hide = message.loading('正在创建供应商采购单', 0);
    const resp = await purchaseOrderApi.reCreatePurchaseSupplierOrder(supplierOrder.id);
    console.log(resp);

    hide();
    message.success('供应商采购单已创建');

    fetchPurchaseOrders({
      pagination
    })
  }


  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '72px' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={12}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  采购单
                </h3>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '82px', paddingBottom: '32px' }}>
        <SearchOrders />
        <div className="order-detail-list" style={{ margin: 20 }}>
          {
            data.map(purchaseOrder => <PurchaseOrderCard key={purchaseOrder.id} purchaseOrder={purchaseOrder} onConfirm={handleConfirmPurchaseOrder} onReCreateSupplierOrder={handleReCreatePurchaseSupplierOrder}/>)
          }

          {/* <PurchaseOrderCard /> */}
        </div>
        <div className="pagination" style={{ display: 'flex', justifyContent: 'flex-end', margin: 20 }} >
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={['5', '10', '20']}
          />
        </div>
      </div>
    </>
  )
}