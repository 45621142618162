import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const SupplierCrossOrderProductLogisticsStatus = ({ status }) => {
  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return '未发货';
      case 2:
        return '已发货';
      case 3:
        return '已收货';
      case 4:
        return '已退货';
      case 5:
        return '部分发货';
      case 8:
        return '无物流信息';
      default:
        return '无物流信息';
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};


export default SupplierCrossOrderProductLogisticsStatus;