export function constructAddress(json) {
  const {
    deliveryCity,
    deliveryCompany,
    deliveryFirstName,
    deliveryLastName,
    deliveryPostCode,
    deliveryProvince,
    deliveryState,
    deliveryStreetAddress,
    deliveryTelephone
  } = json;

  // const email = "softlight0629@gmail.com";
  // const country = "Sweden";
  // const state = "哥特兰岛";

  let addressComponents = [];

  if (deliveryFirstName) addressComponents.push(deliveryFirstName);
  if (deliveryLastName) addressComponents.push(deliveryLastName);
  // addressComponents.push(email);
  // addressComponents.push(country);
  // addressComponents.push(state);
  if (deliveryState) addressComponents.push(deliveryState);
  if (deliveryProvince) addressComponents.push(deliveryProvince);
  if (deliveryCity) addressComponents.push(deliveryCity);
  if (deliveryPostCode) addressComponents.push(deliveryPostCode);
  if (deliveryStreetAddress) addressComponents.push(deliveryStreetAddress);
  if (deliveryCompany) addressComponents.push(deliveryCompany);
  // if (deliveryTelephone) addressComponents.push(deliveryTelephone);

  return addressComponents.join(", ");
}