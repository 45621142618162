import React, { useState, useEffect } from 'react';
import { Descriptions, Card, Button, Table, Tag, Space, Row, Col, DatePicker, Select, Input, Pagination } from 'antd';
import { FileTextOutlined, ClockCircleOutlined, UserOutlined, FormOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ShopProductCategoryManager from './component/ShopProductCategoryManager';
import ShopGeneralManager from './component/ShopGeneralManager';
import shopApi from '../servapi/shop';
const { Option } = Select;
const { RangePicker } = DatePicker;


const ShopManager = props => {
  const [shopId, setShopId] = useState(null);
  const [shops, setShops] = useState([]);
  const [currentNav, setCurrentNav] = useState('general')
  useEffect(() => {
    shopApi.getShopList()
      .then(shops => {
        setShops(shops);
        setShopId(shops[0].id)
      })
      .catch(console.log);
  }, []);

  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{}}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={24}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  店铺管理
                </h3>
                <Select
                  value={shopId}
                  onChange={setShopId}
                  placeholder="全部"
                  style={{ width: '300px' }}
                  allowClear
                >
                  {
                    shops.map(shop => (<Option value={shop.id}>{shop.shopName} - {shop.shopUrl}</Option>))
                  }
                </Select>
              </div>

            </Col>
            <Col span={24}>
              <nav class="page-layout-nav">
                <div class="nav-item"><a href="javascript:void(0);" class={`${currentNav === 'general' ? 'active' : ''}`} onClick={() => setCurrentNav('general')}>基本设置</a></div>
                <div class="nav-item"><a href="javascript:void(0);" class={`${currentNav === 'catalog' ? 'active' : ''}`} onClick={() => setCurrentNav('catalog')}>类目管理</a></div>
                {/* <div class="nav-item"><a href="/cn/admin/services_assemble/my_services_settings">服务预选</a></div> */}
              </nav>
            </Col>
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '102px', paddingBottom: '32px' }}>
        <div style={{ marginTop: 24 }}>
          {
            currentNav === 'general' && <ShopGeneralManager shopId={shopId}/>
          }
          {
            currentNav === 'catalog' && <ShopProductCategoryManager shopId={shopId}/>
          }
        </div>
      </div>
    </>
  )
}

export default ShopManager;