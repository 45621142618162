const storage = {
  // 存储数据
  setItem: function(key, value) {
    if (key && value !== undefined) {
      try {
        const data = typeof value === 'object' ? JSON.stringify(value) : value;
        localStorage.setItem(key, data);
      } catch (error) {
        console.error('Error saving to localStorage', error);
      }
    }
  },

  // 获取数据
  getItem: function(key) {
    if (key) {
      try {
        const data = localStorage.getItem(key);
        return data ? JSON.parse(data) : null;
      } catch (error) {
        console.error('Error reading from localStorage', error);
        return null;
      }
    }
    return null;
  },

  // 移除数据
  removeItem: function(key) {
    if (key) {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error('Error removing from localStorage', error);
      }
    }
  },

  // 清空所有存储的数据
  clear: function() {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('Error clearing localStorage', error);
    }
  },

  // 检查某个 key 是否存在
  exists: function(key) {
    return localStorage.getItem(key) !== null;
  }
};

export default storage;